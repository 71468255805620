import { ActionContext, Dispatch } from 'vuex';

export const UPDATE_SELECTABLE = 'UPDATE_SELECTABLE';
export const RESET_SELECTABLE = 'RESET_SELECTABLE';
export const SELECTABLE_RESET = 'SELECTABLE_RESET';
export const SELECTABLE_UPDATED = 'SELECTABLE_UPDATED';

const MUTATE_SELECTABLE = 'MUTATE_SELECTABLE';
const MUTATE_RESET_SELECTABLE = 'MUTATE_RESET_SELECTABLE';

const SORT_COLL = new Intl.Collator('da');
const SORT = (group: Array<string>) => {
	const res = group.sort(function (a, b) {
		return SORT_COLL.compare(a, b);
	});
	return res;
};
const SORT_MULTIPLE = (group: Array<string>) => {
	if (group?.length < 1) {
		return group;
	}
	const uniqueGroup = [];
	group.forEach((name) => {
		const cleanedName = name.replace(/\d/g, '');
		if (!uniqueGroup.includes(cleanedName)) {
			uniqueGroup.push(cleanedName);
		}
	});
	if (uniqueGroup.length === group.length) {
		return group;
	}

	let res = [];
	uniqueGroup.forEach((name) => {
		const sortGroup = group.filter((n) => n.startsWith(name));
		const names = sortGroup.sort(function (a, b) {
			return SORT_COLL.compare(a, b);
		});
		res = res.concat(names);
	});
	return res;
};

export interface SelectableData {
	group: string;
	id: string;
	isSelected: boolean;
	sort?: boolean;
	sortMultiple?: boolean;
}

export interface SelectableContextState {
	selectGroups: Map<string, Array<string>>;
}

const state: SelectableContextState = {
	selectGroups: new Map(),
};

const mutations = {
	[MUTATE_SELECTABLE](state: any, selectable: SelectableData) {
		let group = state.selectGroups.get(selectable.group); // i.e. "basket"
		if (!group) {
			group = [];
		}
		if (selectable.isSelected && group.includes(selectable.id)) {
			// no change
			return;
		}
		group = group.filter((element) => element !== selectable.id);
		if (selectable.isSelected) {
			group.push(selectable.id);
		}

		if (selectable.sort) {
			// sort all
			group = SORT(group);
		} else if (selectable.sortMultiple) {
			// sort multiple elements
			group = SORT_MULTIPLE(group);
		}
		state.selectGroups.set(selectable.group, group);
	},
	[MUTATE_RESET_SELECTABLE](state: any, group: string) {
		state.selectGroups.set(group, []); // i.e. "basket"
	},
	[SELECTABLE_UPDATED](state: any, payload: any) {
		// notify subscribers that basket is changed
	},
};

const actions = {
	async [UPDATE_SELECTABLE]({ commit, state, dispatch }: ActionContext<any, any>, selectable: SelectableData) {
		commit(MUTATE_SELECTABLE, selectable);
		dispatch(SELECTABLE_UPDATED, selectable);
	},
	async [RESET_SELECTABLE]({ commit, state, dispatch }: ActionContext<any, any>, group: string) {
		commit(MUTATE_RESET_SELECTABLE, group);
		dispatch(SELECTABLE_UPDATED, { group, resetBasket: true });
	},
	async [SELECTABLE_UPDATED]({ commit, state, dispatch }: ActionContext<any, any>, payload: any) {
		commit(SELECTABLE_UPDATED, payload); // notify subscribers that basket has changed
	},
};

export default {
	state,
	mutations,
	actions,
};
