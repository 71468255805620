import { getCookie } from './views/calculators/services/cookieService';
import { AppSendMessage, isApp } from './views/calculators/services/environmentService';
import store from './store/store';
export async function runAtStart() {
	handleAppCookie();

	window.addEventListener('load', function () {
		if (window.fetch && (window as any).usabilla) {
			(window as any).usabilla('setEventCallback', function (category, action, label, value, userData) {
				if (action === 'In-Page:Success') {
					//label = comment
					//value = 1-5 of score
					//userData = Responses provided by user
					fetch('/api/profiles/event', {
						method: 'POST',
						headers: { 'Content-Type': 'application/json;charset=utf-8' },
						credentials: 'same-origin',
						body: JSON.stringify({
							eventType: 'UsabillaFeedback',
							payload: { comment: label, score: value, userData: userData },
							userInfo: {},
							value: value,
							PageUri: window.location.href,
							PageTitle: document.title,
						}),
					});
				}
			});
		}
	});
}

const handleAppCookie = () => {
	// const doLog = false;

	//to allow Apple to publish our app
	if (isApp()) {
		if (window.location.href.indexOf('cookies=required_only') > -1) {
			const intervalId = setInterval(() => {
				if ((window as any).CookieInformation !== undefined) {
					(window as any).CookieInformation.declineAllCategories();
				}
				clearInterval(intervalId);
			}, 1000);
		}
	}

	// Get consent cookie and send to app
	const interval = setInterval(() => {
		// logToUi('isApp():', doLog);
		// logToUi(isApp(), doLog);
		// logToUi('store.getters.getConsentSetInApp:', doLog);
		// logToUi(store.getters.getConsentSetInApp, doLog);

		if (isApp() && store.getters.getConsentSetInApp === false) {
			const cookieInformationConsent = getCookie('CookieInformationConsent');
			// logToUi('cookieInformationConsent:', doLog);
			// logToUi(cookieInformationConsent, doLog);
			if (cookieInformationConsent !== null) {
				store.dispatch('setConsentSetInApp', true);
				AppSendMessage({ CONSENT_COOKIE: cookieInformationConsent });
				// logToUi('Success', doLog);
				clearInterval(interval);
			}
		}
		if (!isApp()) {
			clearInterval(interval);
		}
	}, 3000);
};
