import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/views/calculators/services/environmentService';
import {
	CommonModel,
	CompleteCalculation,
	Model,
	PersonInfoHomeFamily,
	mockAdditionalInfo,
	mockContactInformation,
	mockPersonInfo,
} from '../types/CommonModel';
import { cloneDeep } from 'lodash';
import { familyInfo6Persons } from '../accidentChildren/AccidentChildrenModel';
// list and order of steps
export const STEPS: Array<string> = [
	STEP.PERSON_INFO,
	STEP.CHILDREN_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class FamilyModel extends CommonModel implements Model {
	public familyInfo = cloneDeep(familyInfo6Persons);

	public constructor() {
		super(STEPS, InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING);
	}

	public personInfo = new PersonInfoHomeFamily();

	public calculationAccident = new CompleteCalculation();

	public static mock(model: FamilyModel) {
		if (isDevelop) {
			mockPersonInfo(model);

			Object.assign(model.personInfo, {
				familyMemberCount: '4 personer',
				familyMemberCountId: 4,
				thatchedRoof: 'ja',
			});

			Object.assign(model.familyInfo, {
				personCount: '3 børn',
				personCountId: 2,
				subtitle: '3 børn',

				// personCount:"2 Børn",
				// personCountId:1,
				// subtitle:"2 børn",

				age0: 0,
				cpr0: '1212201212',
				name0: 'Lisa Test',
				existInsurance0: 'nej',

				age1: 3,
				cpr1: '1212221212',
				name1: 'Peter Test',
				existInsurance1: 'nej',

				age2: 4,
				cpr2: '1212211212',
				name2: 'Franke Test',
				existInsurance2: 'nej',
			});

			mockAdditionalInfo(model);

			mockContactInformation(model);
		}
	}
}
