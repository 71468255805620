import { createStore } from 'vuex';

import Actions from './actions';
import Getters from './getters';
import Mutations from './mutations';
import { EpiContentState } from '@/store/modules/epiContent';
import { EpiContextState } from '@/store/modules/epiContext';
import epiContent from '@/store/modules/epiContent';
import epiContext from '@/store/modules/epiContext';
import selectableContext, { SelectableContextState } from '@/store/modules/selectableContext';
import authenticationContext, { AuthenticationState } from '@/store/modules/authenticationContext';
import calculatorContext, { CalculatorContextState } from '@/store/modules/calculatorContext';
import trackingContext, { TrackingContextState } from '@/store/modules/trackingContext';
import { isDevelop } from '@/views/calculators/services/environmentService';
import { Modal } from '@/views/calculators/services/AlertService';
import { UpSaleCampaignData } from '@/views/calculators/services/campaignService';

export type CmsRootState = {
	customer?: any;
	customerProducts?: any;
	isTouchDevice: boolean;
	epiRouting: [];
	usabilla: { mounted: number; count: number };
	modal: Modal;
	epiContent?: EpiContentState;
	epiContext?: EpiContextState;
	// appReplaceRoute: boolean,
	selectableContext?: SelectableContextState;
	authenticationContext?: AuthenticationState;
	calculatorContext?: CalculatorContextState;
	trackingContext?: TrackingContextState;
	recaptchaSiteKey: string;
	showSpinner: boolean;
	openServiceUrl: string;
	openServiceCalculatorUrl: string;
	mailOSSNoLogin: string;
	teamMailOSSNoLogin: string;
	mailNoLoginLead: string;
	uiVersion: string;
	versionInit: boolean;
	frontendConfiguration: any;
	logOutUrl: string;
	idleTimeOutMinutes: number;
	menuModel: any;
	consentSetInApp: boolean;
	trackingIsAllowed: boolean;
	toUrl: { path: string; fullPath: string };
	fromUrl: { path: string; fullPath: string };
	headerBadgeShown: Map<number, boolean>;
	UsabillaReceiptId: string;
	UsabillaProductId: string;
	doMock: boolean;
	upSaleCampaignData: UpSaleCampaignData;
	upSaleCampaignCookieTtl: number;
	consent: { text: string; linkText: string; detailsText: string };
};

const rootState = {
	customer: undefined,
	customerProducts: undefined,
	isTouchDevice: false,
	epiRouting: [],
	usabilla: { count: 0, mounted: 0 },
	modal: { id: 'none', show: false },
	recaptchaSiteKey: undefined,
	showSpinner: false,
	openServiceUrl: '/api/naboo/v1/serviceproxy',
	openServiceCalculatorUrl: '/api/aquaman/v1/openserviceproxy',
	mailOSSNoLogin: '/api/aquaman/v1/checkoutmail/sendossnotloggedin',
	teamMailOSSNoLogin: '/api/aquaman/v1/checkoutmail/sendteamnotloggedin',
	mailNoLoginLead: '/api/aquaman/v1/checkoutmail/notloggedinlead',
	logOutUrl: '/auth/logout?applicationId=almbrand&redirectUri=https://www.almbrand.dk',
	idleTimeOutMinutes: 45,
	uiVersion: undefined,
	versionInit: false,
	frontendConfiguration: undefined,
	menuModel: undefined,
	consentSetInApp: false,
	trackingIsAllowed: true, // should be handled by GTM, so always track
	toUrl: undefined,
	fromUrl: undefined,
	headerBadgeShown: new Map(),
	UsabillaReceiptId: '5ed8a776b78544680f27fc38',
	UsabillaProductId: '651a80fb645fcf22c61ef36a',
	doMock: isDevelop && location?.href?.toLowerCase()?.includes('mockdata=true') ? true : false,
	upSaleCampaignData: undefined,
	upSaleCampaignCookieTtl: 3 * 60 * 1000,
	consent: { text: undefined, linkText: undefined, detailsText: undefined },
} as CmsRootState;

export default createStore<CmsRootState>({
	state: rootState,
	mutations: Mutations,
	actions: Actions,
	getters: Getters,
	modules: {
		epiContent,
		epiContext,
		selectableContext,
		authenticationContext,
		calculatorContext,
		trackingContext,
	},
});
