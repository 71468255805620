import { CalculatorUiComponent } from '../BuyInsuranceComponent';

export default abstract class MapOrderFlow {
	public uiComp: CalculatorUiComponent;
	public model;
	public cms;
	public pack;

	public constructor(uiComp: CalculatorUiComponent) {
		this.uiComp = uiComp;
		this.model = this.uiComp.model;
		this.cms = this.uiComp.cms;
		this.pack = this.model.choosePackage.selectedPackage;
	}
}
