import AxiosService from '@/views/calculators/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import { addProp } from '@/views/calculators/services/OrderService';

const axiosService = new AxiosService();

export const sendMailToOssNoLogin = async (params: Map<string, string>): Promise<any> => {
	setUiVersion(params);
	return await sendMail(params, defaultConfig.baseUrl + store.state.mailOSSNoLogin);
};

export const sendMailToTeamNoLogin = async (params: Map<string, string>): Promise<any> => {
	setUiVersion(params, false);
	return await sendMail(params, defaultConfig.baseUrl + store.state.teamMailOSSNoLogin);
};

export const sendMailNoLoginLead = async (params: Map<string, string>): Promise<any> => {
	setUiVersion(params, true);
	return await sendMail(params, defaultConfig.baseUrl + store.state.mailNoLoginLead);
};

export const setUiVersion = (params: Map<string, string>, addLineBreaks = true) => {
	try {
		let desc = params.get('description');
		if (desc) {
			if (addLineBreaks) {
				desc += '<br/><br/>';
			}
			desc += addProp('version', store.state.uiVersion);
			desc += addProp('userAgent', window?.navigator?.userAgent);
			desc += addProp('transactionId', store.state.calculatorContext.transactionId); // transactionId has to be last value in description
			params.set('description', desc);
		}
	} catch (error) {
		// Too bad
		return;
	}
};

const sendMail = async (params: Map<string, string>, to: string): Promise<any> => {
	const obj = Array.from(params).reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {});
	return await axiosService.post(to, obj);
};
