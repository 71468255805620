import { exception } from '@/appinsights/logging/ApplicationInsightLog';

export const getCookie = (name: string): string => {
	const cValue = document.cookie;
	const parts = cValue.split(';').map((x) => x.split('='));
	const cookie = parts.find((x) => x[0].trim() === name);
	if (cookie) {
		const value = cookie[1]?.trim();
		return value;
	}
};

export const setCookie = (name: string, value: string, expireMillis: number) => {
	const now = new Date();
	now.setTime(now.getTime() + expireMillis);
	document.cookie = `${name}=${value}; expires=${now.toUTCString()}; path=/`;
};

export const deleteCookie = (name: string) => {
	document.cookie = name + '=; Max-Age=0';
};

export const getCookieAsJson = <T>(name: string): T => {
	try {
		const rawCookieValue = getCookie(name);
		if (!rawCookieValue) {
			return;
		}

		return JSON.parse(getCookie(name));
	} catch (error) {
		exception(error);
	}
};
