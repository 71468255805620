import InputFieldComponent from '../inputFieldComponent/InputFieldComponent.vue';
import BulletListComponent from '../bulletListComponent/bulletListComponent.vue';
import ModalComponent from '../ModalComponent/ModalComponent.vue';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';

@Options({
	name: 'SelectModalComponent',
	components: {
		InputFieldComponent,
		ModalComponent,
		BulletListComponent,
	},
	props: {
		name: String,
		id: String,
		label: String,
		preSelect: String,
		innerData: Array,
		overflow: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		selected: { type: Boolean, default: false },
		placeholder: String,
		visibleError: { type: Boolean, default: false },
		readonlyValue: String,
		errorMsgOverride: String,
	},
})
export default class SelectModalComponent extends Vue {
	name: string;
	id: string;
	label: string;
	preSelect: string;
	innerData: Array<any>; // can be ['lorem', 'ipsum', 3, 4] or [{ title: 'Title', desc: 'Whatever' }] or [{ name: 'Title' }]
	overflow?: boolean; // text ellipsis
	disabled?: boolean;
	selected?: boolean; // Which checkMark to show
	placeholder: string;
	visibleError: boolean;
	readonlyValue: string;
	errorMsgOverride: string;

	public inputKey = 'inputKey';
	public inputValue: string = null;

	public created() {
		if (this.preSelect) {
			this.inputValue = this.preSelect;
		}
	}

	get list() {
		// if array contains objects, return name or title values only
		if (this.innerData.some((item) => typeof item === 'object')) {
			return this.innerData.map((item) => {
				return item.name || item.title;
			});
		}
		return this.innerData;
	}

	public selectItem(evt): void {
		this.inputValue = evt.value;
		if (this.inputValue === undefined) {
			this.inputValue = this.list[0];
		}

		setTimeout(
			() => {
				// wait for animation success
				this.$emit('SelectModalComponent:selected', {
					selectedValue: this.inputValue,
					id: this.id,
				});
				store.dispatch('toggleModal', {
					isActive: false,
					id: this.id,
				});

				this.inputKey += '1'; // refresh inputField
			},
			evt.instantly ? 0 : 600
		);
	}

	public open() {
		const input = document.getElementById(this.id + 1);
		input.blur();
		store.dispatch('toggleModal', {
			isActive: true,
			id: this.id,
		});
	}
}
