import { isDevelop } from '../services/environmentService';
import { STEP } from '../services/UiStepService';
import {
	Campaign,
	ContactInformationCommon,
	mockContactInformation,
	mockPersonInfo,
	Payment,
	PersonInfoCommon,
} from '../types/CommonModel';

// list and order of steps
export const STEPS: Array<string> = [STEP.OVERVIEW, STEP.CONTACT_INFORMATION, STEP.PAYMENT, STEP.ORDER];

class ContactInformationBasket extends ContactInformationCommon {
	rki: 'ja' | 'nej';
	skader: '0' | '1' | '2' | '3+';
}
export class BasketModel {
	public campaign = new Campaign();

	public personInfo = new PersonInfoCommon();

	public choosePackage = {
		monthYear: <string>undefined,
	};
	public contact_information = new ContactInformationBasket();

	public overview = {
		subtitle: <string>undefined,
	};
	public payment = new Payment();
	public pristineStep = new Map([
		[STEP.OVERVIEW.valueOf(), true],
		[STEP.CONTACT_INFORMATION.valueOf(), true],
		[STEP.PAYMENT.valueOf(), true],
	]);
	public calculation = {
		discount: <number>undefined,
		discountDisplay: <string>undefined,
	};
	public productName = 'kurv';
	public showReceipt = false;
	public currentCardName = 'none';
	public updateAll = 'updateAll';

	public static mock(model: BasketModel) {
		if (isDevelop) {
			mockPersonInfo(model);
			mockContactInformation(model as any);
		}
	}
}
