import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { CarSettings } from './CarSettings';
import CarCalculator from './CarCalculator';
import CarInfoStepComponent from './steps/CarInfoStepComponent.vue';
import PersonInfoStepComponent from './steps/PersonInfoStepComponent.vue';
import ChoosePackageStepComponent from '../commonSteps/ChoosePackageStepComponent.vue';
import ContactInformationStepComponent from '../commonSteps/ContactInformationStepComponent.vue';
import OverviewStepComponent from '../commonSteps/OverviewStepComponent.vue';
import AdditionalInfoStepComponent from './steps/AdditionalInfoStepComponent.vue';
import PaymentStepComponent from '../commonSteps/PaymentStepComponent.vue';
import ReceiptStepComponent from '../commonSteps/ReceiptStepComponent.vue';
import CampaignStepComponent from '../commonSteps/CampaignStepComponent.vue';
import { CarModel, STEPS } from './CarModel';
import { PropType } from 'vue';
import { CardBlock, HtmlBlock, UsabillaInPageBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import { CALCULATOR_LOADED, INIT_CALCULATOR, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import { gotoAddInsurances, nextStep, setFirstCard } from '../services/NavigationService';
import { resetSelectedCalculation, subscribeBasketChange } from '../services/CalculationService';
import {
	getShowReceipt,
	handleValidCustomerAge,
	isValidAdditionalInfo,
	isValidAlmbrandProducts,
	isValidContactInfo,
	isValidPackage,
	isValidPaymentInfo,
	Validator,
} from '../services/ValidationService';
import { getCardTitle, getSubtitle, setDetailsYearlyPrice, setHighLights } from '../services/ProductService';
import { initComponent, STEP } from '../services/UiStepService';
import MapOrderDescription from './MapOrderDescription';
import { CalculatorUiComponent } from '../BuyInsuranceComponent';
@Options({
	name: 'BuyInsuranceCarComponent',
	props: {
		calculatorInfoBlock: Object as PropType<Array<HtmlBlock>>,
		contentBlocks: Object as PropType<Array<CardBlock>>,
		cardReceipt: Object as PropType<CardBlock>,
		settingsBlock: Object as PropType<HtmlBlock>,
		usabillaBlock: Object as PropType<UsabillaInPageBlock>,
		contentUrl: String, // Used by service to handle browser history
		headerBadgeTxt: String,
		hasBadgeText: Boolean,
	},
	components: {
		UsabillaInPageComponent,
		CarInfoStepComponent,
		PersonInfoStepComponent,
		ChoosePackageStepComponent,
		OverviewStepComponent,
		AdditionalInfoStepComponent,
		ContactInformationStepComponent,
		PaymentStepComponent,
		ReceiptStepComponent,
		CampaignStepComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class BuyCar extends Vue implements CalculatorUiComponent {
	contentBlocks: Array<CardBlock>;
	cardReceipt: CardBlock;
	settingsBlock: HtmlBlock;
	usabillaBlock: UsabillaInPageBlock;
	contentUrl: string; // Used by service to handle browser history
	headerBadgeTxt: string;
	hasBadgeText: boolean;
	calculatorInfoBlock: HtmlBlock;

	// list and order of steps
	public steps: Array<string> = STEPS;

	public model!: CarModel; // model from store
	public cms!: CarSettings; // setting from store
	public calculator: CarCalculator = null;
	public componentInit = false;
	public checkout = false;
	private mapOrderDescription: MapOrderDescription;
	private unsubScribeBasketChange: Function;

	public async created() {
		if (!store.getters.getActiveCalculator) {
			const cms = new CarSettings(this.settingsBlock);
			const model: CarModel = new CarModel();
			if (cms.mockData) {
				CarModel.mock(model);
			}
			await store.dispatch(INIT_CALCULATOR, {
				cms,
				model,
			});
		} else {
			await store.dispatch(CALCULATOR_LOADED);
		}

		if (!(await initComponent(this))) {
			return;
		}

		this.calculator = new CarCalculator(this);

		if (this.$route.query.licenseplate) {
			this.model.carInfo.licenseplate = this.$route.query.licenseplate;
		}

		if (this.model.campaign.valid) {
			if (this.$route.query.customerAge) {
				this.model.personInfo.customerAge = this.$route.query.customerAge as string;
			}
			if (this.$route.query.zipCode || this.$route.query.zipcode) {
				// klk address
				this.model.personInfo.zipCode = this.$route.query.zipCode as string;
			}

			try {
				const yearlyMileageIndex = this.$route.query.yearlyMileageIndex as string;
				const index = parseInt(yearlyMileageIndex);
				const yearlyMileage = this.cms.kmForbrug[index];
				if (yearlyMileage) {
					this.model.carInfo.yearlyMileage = this.cms.kmForbrug[index];
				}
			} catch (e) {}

			if (this.$route.query.displayText || this.$route.query.modelsearch || this.$route.query.modelSearch) {
				this.model.carInfo.displayText =
					this.$route.query.displayText || this.$route.query.modelsearch || this.$route.query.modelSearch;
				this.model.carInfo.searchBrandModel = true;
				this.model.carInfo.isBrandModelSelected = true;
			}
		}
		setFirstCard(this);
	}

	public mounted(): void {
		this.unsubScribeBasketChange = subscribeBasketChange(this);
	}

	beforeUnmount() {
		if (this.unsubScribeBasketChange) {
			this.unsubScribeBasketChange();
		}
	}

	// called by service
	public addResetSubscription() {
		const unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === RESET_CALCULATORS) {
				setTimeout(() => {
					this.checkout = true;
					store.dispatch(INIT_CALCULATOR, {
						cms: this.cms,
						model: new CarModel(),
					});
					unsubscribe();
				}, 2000);
			}
		});
	}

	public get showValidNowOption() {
		if (this.isNewDriver) {
			return true;
		}
		if (!this.model.additionalInfo.existInsurance && !this.model.additionalInfo.existFormerInsurance) {
			return false;
		}

		if (this.model.additionalInfo.existInsurance === 'ja' && this.model.additionalInfo.existingInsurance) {
			return true;
		}

		if (this.model.additionalInfo.existInsurance === 'nej') {
			if (this.model.additionalInfo.existFormerInsurance === 'nej') {
				return true;
			}
			if (this.model.additionalInfo.existFormerInsurance === 'ja' && this.model.additionalInfo.formerInsurance) {
				return true;
			}
		}
		return false;
	}

	public get isNewDriver(): boolean {
		// customer has never had a car
		return (
			this.model.carInfo.carOwnerYears && this.cms.carOwnerYears.indexOf(this.model.carInfo.carOwnerYears) === 0
		);
	}

	public async gotoCard(cardName: string): Promise<boolean> {
		if (cardName === STEP.CAR_INFO || cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
			resetSelectedCalculation(this.model);
		}
		if (cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
			store.state.showSpinner = true;
			const isSpecialCar = await this.calculator.isSpecialCar();

			store.state.showSpinner = false;
			if (isSpecialCar) {
				this.model.currentCardName = cardName;
				this.gotoCard(STEP.CAR_INFO);
				return false;
			}
		}
		if (cardName === STEP.PACKAGE) {
			this.calculator.setupExcessList();
			// check Age:
			if (!handleValidCustomerAge(this.model, this.cms)) {
				this.gotoCard(STEP.PERSON_INFO);
				return false;
			}

			// under 25 år, kun én selvrisiko og beregn nu
			if (this.model.ownRiskOptions.length === 1) {
				this.model.choosePackage.ownRiskId = this.model.ownRiskOptions[0];
			}
		}

		if (cardName === STEP.PAYMENT) {
			// force user to choose yearly/monthly payment to ensure progressive steps
			this.model.choosePackage.monthYear = undefined;
			this.model.payment.userSelected = false;
		}
		// const previousCard = this.model.currentCardName;
		// all validations are good, so make sure blur doesn't set errors on fields
		this.model.currentCardName = cardName;

		if (cardName === STEP.ORDER) {
			store.state.showSpinner = true;
			if (!this.isValid(this.model.currentCardName)) {
				store.state.showSpinner = false;
				return false;
			}
			this.mapOrderDescription = new MapOrderDescription(this);
			await this.mapOrderDescription.orderByEmail();
			return false;
		}
		return true;
	}

	/**
	 * when nextbtn is clicked - check for valid
	 * @param cardName
	 */
	public nextStep(cardName: string, addToHistory = true) {
		nextStep(cardName, addToHistory, this);
	}

	public getSubtitle(cardName) {
		return getSubtitle(cardName, this.model);
	}

	public getExcessAmount(): string {
		const inx = this.model.ownRiskOptions.indexOf(this.model.choosePackage.ownRiskId);
		return this.model.ownRiskLabels[inx];
	}

	public isActiveCard(cardName: string): boolean {
		return cardName === this.model.currentCardName;
	}

	public isValid(cardName): boolean {
		switch (cardName) {
			case STEP.CAR_INFO:
				return !!(this.model.carInfo.variantId && this.model.carInfo.yearlyMileage);
			case STEP.PERSON_INFO:
				const okPerson =
					this.model.carInfo.carOwnerYears &&
					this.model.personInfo.address &&
					Validator.isValidAge(this.model.personInfo.customerAge) &&
					isValidAlmbrandProducts(this.model);
				if (okPerson) {
					this.model.personInfo.customerAge = this.model.personInfo.customerAge.trim().replace(/\D/g, '');
					// const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
					this.model.personInfo.subtitle = `${this.model.personInfo.customerAge} år, ${this.model.personInfo.address}`;
				}
				return !!okPerson;
			case STEP.PACKAGE:
				return isValidPackage(this);
			case STEP.OVERVIEW:
				return isValidPackage(this);
			case STEP.CONTACT_INFORMATION:
				return isValidContactInfo(this.model, this.cms);
			case STEP.ADDITIONAL_INFO:
				let ok = isValidAdditionalInfo(this.isNewDriver, this.model, this.cms);
				if (!ok) {
					return ok;
				}
				if (this.model.additionalInfo.existInsurance === 'nej') {
					ok = this.model.additionalInfo.existFormerInsurance !== undefined;
					if (!ok) {
						return false;
					}
					if (this.model.additionalInfo.existFormerInsurance === 'ja') {
						ok = Validator.isValidExistingInsurance(this.model.additionalInfo.formerInsurance);
					}
				}
				return !!ok;
			case STEP.PAYMENT:
				return isValidPaymentInfo(this.model, this.cms);
			default:
				return true;
		}
	}

	// called by service
	public setOverviewData() {
		setHighLights(this.model);

		this.model.overview.details = [
			this.model.carInfo.car,
			this.model.personInfo.customerAge + ' år',
			this.model.personInfo.address,
			'Selvrisiko: ' + this.getExcessAmount(),
			'År med egen bil: ' + this.model.carInfo.carOwnerYears,
			'Kørselsbehov: ' + this.model.carInfo.yearlyMileage,
		];
		setDetailsYearlyPrice(this.model, this.cms);
	}

	/** called by service */
	public buildDescriptionForEmail(desc: string) {
		return this.mapOrderDescription.buildDescriptionForEmail(desc);
	}

	public getExistingInsuranceText() {
		return this.cms.existingInsuranceText.replace('¤carInfo', this.model.carInfo.car);
	}

	public gotoAddInsurances(step: string) {
		gotoAddInsurances(step, this);
	}

	public get showReceipt() {
		return getShowReceipt(this);
	}

	public getCardTitle(card): string {
		return getCardTitle(card, this.model, this.cms);
	}
}
