import { mapState } from 'vuex';
import { SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import { CardBlock } from '@/cms/definitions/content-types';
import { BasketSettings } from '../BasketSettings';
import OverviewCalculatorStepComponent from './OverviewCalculatorStepComponent.vue';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { path } from '../../services/NavigationService';
import { getSuggestedProductsFlow } from '../../services/SuggestProductService';
import { getMonthlyTotalPrice } from '../../services/CalculationService';

@Options({
	name: 'OverviewBasketStepComponent',
	components: {
		OverviewCalculatorStepComponent,
	},
	computed: mapState<any>({
		basketCms: (state) => state.calculatorContext.basket.cms,
		basketModel: (state) => state.calculatorContext.basket.model,
	}),
	props: {
		card: Object, // Card from cms
		isValid: Boolean, // whether card is complete
	},
})
export default class OverviewBasketStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // whether card is complete

	public basketCms!: BasketSettings; // settings from CMS (store)
	public basketModel!: any;

	public updateProdKey = 'updateProdKey';
	public productNames: Array<string> = [];
	public suggest: { suggestedProducts: string; discountDisplay: string } = {
		suggestedProducts: undefined,
		discountDisplay: undefined,
	};
	public suggestText: string = null;
	public suggestText1: string = null;
	public hasPrices = false;

	private unsubscribe: Function;

	public async created() {
		this.productNames = await store.getters.getSelectableGroup('basket');
		this.unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
				this.productNames = store.getters.getSelectableGroup('basket');
				this.updateProdKey += '1';
				this.setSuggestText();
			}
		});
		this.setSuggestText();
	}

	public beforeDestroy() {
		this.unsubscribe();
	}

	public mounted(): void {
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount(): void {
		window.removeEventListener('keyup', this.moveOn);
	}
	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	private setSuggestText() {
		this.suggestText = this.getSuggestText();
		this.suggestText1 = this.getSuggestText1();
	}
	private getSuggestText(): string {
		if (!this.basketCms.suggestProductsText) {
			return undefined;
		}
		this.suggest = getSuggestedProductsFlow(this.basketCms);
		if (!this.suggest) {
			return undefined;
		}
		return this.basketCms.suggestProductsText
			.replace('¤discount', this.suggest.discountDisplay)
			.replace('¤products', this.suggest.suggestedProducts);
	}
	private getSuggestText1(): string {
		if (this.suggest && this.basketCms.suggestProductsText1) {
			return this.basketCms.suggestProductsText1
				.replace('¤discount', this.suggest.discountDisplay)
				.replace('¤products', this.suggest.suggestedProducts);
		}
		return undefined;
	}

	public get forsikringerLink() {
		// const url = urlService.ensureCorrectLink(path + 'forsikringer');
		const url = path + 'forsikringer';
		return url;
	}

	public getMonthlyTotalPrice(monthly: boolean): string {
		return getMonthlyTotalPrice(monthly, this, this.productNames);
	}

	public nextStep(): void {
		this.$emit('nextStep', this.card.routePath);
	}
}
