import AxiosService from '@/views/calculators/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import { CalculatorUiComponent } from '@/views/calculators/BuyInsuranceComponent';
import { addProp, removeHtmlTags } from '@/views/calculators/services/OrderService';
import { capitalizeFirstLetter } from '@/views/calculators/services/UiStepService';
import { isDevelop } from './environmentService';
import { trackError } from './trackingUtility';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

const axiosService = new AxiosService();

export const sendToSalesForce = async (
	yearlyPrice: number,
	productNames,
	description: string,
	product: CalculatorUiComponent
): Promise<any> => {
	description += addProp('<br/><br/>version', store.state.uiVersion);
	description += addProp('<br/>transactionId', store.state.calculatorContext.transactionId); // transactionId has to be last value in description
	// description += addProp('<br/><br/>userAgent', window?.navigator?.userAgent);

	const headline = `${yearlyPrice} ${productNames}'\n'`;
	const descriptionForSalesForce = headline + removeHtmlTags(description);
	return await postToSalesForce(product, descriptionForSalesForce);
};

/***** private methods *****************/
const postToSalesForce = async (product: CalculatorUiComponent, description: string): Promise<any> => {
	// https://preprod-developer.almbrand.dk/marketing-leads#
	// console.log('postToSalesForce product model', product.model);
	const add = product.model.personInfo;
	const streetNumber = add.houseNr?.trim().replace(/\D/g, '');
	let streetLetter = add.houseNr?.trim().replace(/[^a-z]+/i, '');
	if (streetLetter === '') {
		streetLetter = undefined;
	}

	const contact = product.model.contact_information;
	const phone = contact.phone?.replaceAll(' ', '').replaceAll('+45', '');
	const nameArray = contact.name.trim().split(' ');
	let firstName = nameArray[0];
	const lastName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : 'NA';
	if (lastName !== 'NA') {
		firstName = contact.name.replaceAll(' ' + lastName, '');
	}

	let leadSource = capitalizeFirstLetter(store.getters.getThemeContext ? store.getters.getThemeContext : 'Alm Brand');
	if (leadSource.toLowerCase() === 'almbrand') {
		leadSource = 'Alm Brand';
	}
	const businessBrand = leadSource.replaceAll(' ', '');
	const subLeadSource = leadSource.toLowerCase().replaceAll(' ', '') + '.dk - Online beregner';

	const body = {
		leads: [
			{
				description,
				uri: 'asdasd/aa',
				lead_source: leadSource,
				lead_contact: {
					email: contact.email,
					mobilephone: phone,
					phone,
				},
				sub_lead_source: subLeadSource,
				line_of_business: 'Forsikring',
				segment: 'Privat',
				business_brand: businessBrand,
				lead_name: {
					firstname: firstName,
					lastname: lastName,
				},
				phone: phone,
				lead_address: {
					city: add.zipName || 'København Ø', // ensure lead will always be generated
					floor: add.floorNr,
					floor_door: add.doorNr,
					postal_code: add.zipCode || '2100', // ensure lead will always be generated
					street: add.streetName,
					street_letter: streetLetter,
					street_number: streetNumber,
				},
			},
		],
	};

	let res;
	try {
		let testEvnUri = '';
		if (isDevelop) {
			testEvnUri = product.cms.salesForceTestUri;
		}
		res = await axiosService.post(
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/marketing/leads' + testEvnUri,
			body
		);
		return res;
	} catch (e) {
		trackError('contentData ancestors', res?.status + '');
		exception(e);
	}
};
