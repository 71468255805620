import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';
import getComponentTypeForContent from '@/cms/api/contentComponentSelector';
import store from '@/store/store';
import { setMenu, setVersion } from '@/views/calculators/services/environmentService';

@Options({
	name: 'PageComponentSelector',
	computed: mapState<any>({
		model: (state) => state.epiContent.model,
		modelLoaded: (state) => state.epiContent.modelLoaded,
	}),
})
export default class PageComponentSelector extends Vue {
	modelLoaded: boolean;
	model: any;

	public version = null;

	public mounted(): void {
		setMenu(); // async
		if (!store.state.versionInit) {
			setVersion();
		}
		if (store.state.uiVersion) {
			this.version = store.state.uiVersion;
			// console.log('Frontend version', this.version);
		}
	}

	public getComponentTypeForPage(model) {
		// make sure frontendConfiguration is set in the store
		if (!store.state.frontendConfiguration) {
			store.state.frontendConfiguration = this.model.frontendConfiguration;
		}
		return getComponentTypeForContent(model);
	}
}
