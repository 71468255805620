import store from '@/store/store';

export async function createTestCustomer() {
	// CUSTOMER
	store.state.customer = {
		customer_id: '9661014630',
		tags: '#private #customer #insurance',
		name: 'oidc|oauth2-DK-MitID-POCES|{b7cf4e53-b3e8-485d-bae6-f0ac20eb8639}',
		person: { person_id: '9661014630', cpr_nr: '1010680065', firstname: 'Klaus', lastname: 'Lokmann' },
		contact: {
			address: {
				street_name: 'Humlevang',
				building_no_and_letter: '9',
				postal_code: 2740,
				city_name: 'Skovlunde',
			},
			contact_details: [
				{ detail: 'Ja', type: 'Promotion_Digital__c' },
				{ detail: '00358000006meGUAAY', type: 'PersonContactId' },
				{ detail: 'Ja', type: 'Promotion_Phone__c' },
				{ detail: '2021-09-23', type: 'Promotion_SMS_Date__c' },
				{ detail: 'null', type: 'No_Touch__c' },
				{ detail: 'Erol Eskici', type: 'Name' },
				{ detail: 'Ja', type: 'Promotion_SMS__c' },
				{ detail: 'Kundeservice København', type: 'Service_Channel_Department__c' },
				{ detail: 'Skovlunde', type: 'City__c' },
				{ detail: 'null', type: 'Floor_Door__c' },
				{ detail: 'Ja', type: 'Promotion_Letter__c' },
				{ detail: 'null', type: 'Account_Mark__c' },
				{ detail: 'null', type: 'Floor_Side_Code__c' },
				{ detail: '2020-06-25', type: 'Company_Consent_Date__c' },
				{ detail: '1111111111', type: 'Phone' },
				{ detail: 'Privat', type: 'Customer_Type__c' },
				{ detail: '01258000000FJyvAAG', type: 'RecordTypeId' },
				{ detail: '2021-02-16', type: 'Promotion_Email_Date__c' },
				{ detail: '1800-01-01T00:00:00.000+0000', type: 'OBS_Check_Customer__c' },
				{ detail: 'false', type: 'hasWarningRKI__c' },
				{ detail: 'Humlevang', type: 'Street__c' },
				{ detail: 'Ja', type: 'Promotion_Email__c' },
				{ detail: 'Ja', type: 'Company_Consent__c' },
				{ detail: 'false', type: 'hasWarningServiceChannel__c' },
				{ detail: 'false', type: 'hasWarningNotWanted__c' },
				{ detail: 'Erol', type: 'FirstName' },
				{ detail: 'false', type: 'hasWarning__c' },
				{ detail: '2740', type: 'Postal_Code__c' },
				{ detail: '1010101010', type: 'Mobile__c' },
				{ detail: '2020-06-25', type: 'Promotion_Letter_Date__c' },
				{ detail: 'false', type: 'hasWarningLegalStatus__c' },
				{ detail: '2020-06-25', type: 'Promotion_Digital_Date__c' },
				{ detail: '2020-06-25', type: 'Promotion_Phone_Date__c' },
				{ detail: '9', type: 'Street_Number__c' },
				{ detail: 'null', type: 'VAT_Number__c' },
				{ detail: 'Ingen', type: 'Not_Wanted_Customer__c' },
				{ detail: 'null', type: 'Street_Letter__c' },
				{ detail: '00158000006FSHxAAO', type: 'Account_18_character_ID__c' },
				{ detail: 'null', type: 'Data_sharing_consent__c' },
				{ detail: 'null', type: 'Floor__c' },
				{
					detail: '{type=Account, url=/services/data/v52.0/sobjects/Account/00158000006FSHxAAO}',
					type: 'attributes',
				},
				{ detail: 'Ja', type: 'Household_Consent__c' },
				{ detail: '00158000006FSHxAAO', type: 'Id' },
				{ detail: 'Eskici', type: 'LastName' },
				{ detail: '2022-01-13', type: 'Household_Consent_Date__c' },
				{ detail: 'false', type: 'hasWarningDontTouch__c' },
				{ detail: 'false', type: 'hasWarningAccountMark__c' },
				{ detail: 'abklku@almbrand.dk', type: 'Email__c' },
			],
		},
	};
	// CUSTOMER PRODUCTS
	store.state.customerProducts = {
		currency: 'DKK',
		page: { size: 6, total_items: 6, total_pages: 1, number: 1 },
		page_total: { amount: 14777 },
		policies: [
			{
				id: '113331745',
				policy_number: '163787199',
				type: 'travel',
				status: { title: 'status', subtitle: 'I Kraft', type: 'ACTIVE' },
				group: { title: 'Familie og fritid', type: 'family' },
				url: 'https://api-common-policy-singlet.azurewebsites.net/common/policies/113331745?search=true&page=1&size=10',
				title: 'Rejseforsikring',
				subtitle: 'Dækker Europa',
				price: { title: 'Pris pr. år', amount: 870, charged: 'EVERY_YEAR', unit: 'kr' },
				end: { title: 'Forfaldsdato', date: '2023-07-31' },
			},
			{
				id: '110398452',
				policy_number: '163787164',
				type: 'home',
				status: { title: 'status', subtitle: 'I Kraft', type: 'ACTIVE' },
				group: { title: 'Bolig', type: 'housing' },
				url: 'https://api-common-policy-singlet.azurewebsites.net/common/policies/110398452?search=true&page=1&size=10',
				title: 'Indboforsikring',
				subtitle: 'Humlevang 9, 2740 Skovlunde',
				price: { title: 'Pris pr. år', amount: 2403, charged: 'EVERY_YEAR', unit: 'kr' },
				end: { title: 'Forfaldsdato', date: '2023-07-31' },
			},
			{
				id: '113331742',
				policy_number: '163787180',
				type: 'car',
				status: { title: 'status', subtitle: 'I Kraft', type: 'ACTIVE' },
				group: { title: 'Biler og køretøjer', type: 'vehicles' },
				url: 'https://api-common-policy-singlet.azurewebsites.net/common/policies/113331742?search=true&page=1&size=10',
				title: 'Bilforsikring',
				subtitle: 'TOYOTA AVENSIS, AX 89 922',
				price: { title: 'Pris pr. år', amount: 6101, charged: 'EVERY_YEAR', unit: 'kr' },
				end: { title: 'Forfaldsdato', date: '2023-07-31' },
			},
			// {
			// 	id: '110220317',
			// 	policy_number: '163787172',
			// 	type: 'house',
			// 	status: { title: 'status', subtitle: 'I Kraft', type: 'ACTIVE' },
			// 	group: { title: 'Bygninger', type: 'buildings' },
			// 	url: 'https://api-common-policy-singlet.azurewebsites.net/common/policies/110220317?search=true&page=1&size=10',
			// 	title: 'Husforsikring',
			// 	subtitle: 'Humlevang 9, 2740 Skovlunde',
			// 	price: { title: 'Pris pr. år', amount: 3910, charged: 'EVERY_YEAR', unit: 'kr' },
			// 	end: { title: 'Forfaldsdato', date: '2023-07-31' },
			// },
			// {
			// 	id: '110400912',
			// 	policy_number: '163787210',
			// 	type: 'accident',
			// 	status: { title: 'status', subtitle: 'I Kraft', type: 'ACTIVE' },
			// 	group: { title: 'Helbred og sundhed', type: 'health' },
			// 	url: 'https://api-common-policy-singlet.azurewebsites.net/common/policies/110400912?search=true&page=1&size=10',
			// 	title: 'Ulykkesforsikring',
			// 	subtitle: 'Elisa Esgici',
			// 	price: { title: 'Pris pr. år', amount: 1020, charged: 'EVERY_YEAR', unit: 'kr' },
			// 	end: { title: 'Forfaldsdato', date: '2023-07-31' },
			// },
			// {
			// 	id: '110400883',
			// 	policy_number: '163787237',
			// 	type: 'accident',
			// 	status: { title: 'status', subtitle: 'I Kraft', type: 'ACTIVE' },
			// 	group: { title: 'Helbred og sundhed', type: 'health' },
			// 	url: 'https://api-common-policy-singlet.azurewebsites.net/common/policies/110400883?search=true&page=1&size=10',
			// 	title: 'Ulykkesforsikring',
			// 	subtitle: 'Berkay Esgici',
			// 	price: { title: 'Pris pr. år', amount: 472, charged: 'EVERY_YEAR', unit: 'kr' },
			// 	end: { title: 'Forfaldsdato', date: '2023-07-31' },
			// },
		],
	};
}
