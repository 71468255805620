import { isLocalHost } from '@/views/calculators/services/environmentService';
import { ApplicationInsights, SeverityLevel, ICustomProperties } from '@microsoft/applicationinsights-web';

let appInsightsInstance: ApplicationInsights;

// set on initialization from AppInsights
export const initAppInsights = (appInsights) => {
	appInsightsInstance = appInsights;
};

export const verbose = (message: string, properties: ICustomProperties | string): void => {
	trace(message, SeverityLevel.Verbose, getProperties(properties));
};

export const information = (message: string, properties?: ICustomProperties | string): void => {
	trace(message, SeverityLevel.Information, getProperties(properties));
};

export const warning = (message: string, properties?: ICustomProperties | string): void => {
	trace(message, SeverityLevel.Warning, getProperties(properties));
};

export const critical = (exception: Error, message?: string, properties?: ICustomProperties | string): void => {
	errorOrCritical(SeverityLevel.Critical, exception, message, properties);
};

export const exception = (exception: Error, message?: string, properties?: ICustomProperties | string): void => {
	errorOrCritical(SeverityLevel.Error, exception, message, properties);
};

const errorOrCritical = (
	severityLevel: SeverityLevel,
	exception: Error,
	message?: string,
	properties?: ICustomProperties | string
): void => {
	if (!properties) {
		properties = { exception };
	}
	if (!message) {
		message = exception.name;
	}
	try {
		appInsightsInstance.trackException({ exception, severityLevel }, getProperties(properties));
		trace(message, severityLevel, { ...getProperties(properties), exception });
	} catch (err) {
		toConsole(message, severityLevel, { ...getProperties(properties), exception });
	}
};

const trace = (message: string, severityLevel: SeverityLevel, properties: ICustomProperties): void => {
	try {
		appInsightsInstance.trackTrace({ message, severityLevel }, properties);
		if (isLocalHost) {
			toConsole(message, severityLevel, properties);
		}
	} catch (err) {
		toConsole(message, severityLevel, properties);
	}
};

const getProperties = (properties: ICustomProperties | string): ICustomProperties => {
	return typeof properties === 'string' ? { msg: properties } : properties;
};
/* eslint-disable no-console */
const toConsole = (message: string, severityLevel: SeverityLevel, properties: ICustomProperties | string) => {
	switch (severityLevel) {
		case SeverityLevel.Critical:
		case SeverityLevel.Error:
			console?.error(message, properties);
			break;
		case SeverityLevel.Warning:
			console.warn(message, properties);
			break;
		case SeverityLevel.Information:
			console.info(message, properties);
			break;
		case SeverityLevel.Verbose:
			console.debug(message, properties);
			break;
		default:
			console?.log(message, severityLevel, properties);
	}
};
/* eslint-enable no-console */
