import { HtmlBlock } from '@/cms/definitions/content-types';
import BuyInsuranceSettings from '../BuyInsuranceSettings';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

export class TravelSettings extends BuyInsuranceSettings {
	public segmentedProductPropertyPopover: string;
	public segmentedProductPropertiesLabel: string;
	public familyMemberInfo: string;
	public cancelSum: number; // afbestillingssum 30000 || 60000
	public cancelSumId = '3';

	public constructor(settingsBlock: HtmlBlock) {
		super(settingsBlock);
		this.loadTravelSettings();
	}

	/**
	 * load settings from CMS if present
	 */
	private loadTravelSettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');
			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				if (setting.indexOf('segmentedproductpropertypopover=') > -1) {
					this.segmentedProductPropertyPopover = this.getSettingsValue(setting_);
				} else if (setting.indexOf('segmentedproductpropertieslabel=') > -1) {
					this.segmentedProductPropertiesLabel = this.getSettingsValue(setting_);
				} else if (setting.indexOf('familymemberinfo=') > -1) {
					this.familyMemberInfo = this.getSettingsValue(setting_);
				} else if (setting.indexOf('cancelsum=') > -1) {
					this.cancelSum = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('cancelsumid=') > -1) {
					this.cancelSumId = this.getSettingsValue(setting_);
				}
			});
		} catch (e) {
			exception(e);
		}
	}
}
