import { HtmlBlock } from '@/cms/definitions/content-types';
import BuyInsuranceSettings from '../BuyInsuranceSettings';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

export const productId = 'SS1VUF9VUC0yOA==';
export class AccidentSettings extends BuyInsuranceSettings {
	public work: Array<string> = [];
	public workIds: Array<string> = [];
	public seniorStep1 = 66;
	public seniorStep2 = 75;
	public maxCustomerAge = 74;
	public customerMaxAgePopupTitle: string = undefined;
	public customerMaxAgePopupContent: string = undefined;
	public customerMaxAgePopupRedirect: string = undefined;

	public customerOtherMaxAgePopupTitle: string = undefined;
	public customerOtherMaxAgePopupContent: string = undefined;
	public customerOtherMaxAgePopupRedirect: string = undefined;

	public customerOtherAgePopupTitle: string = undefined;
	public customerOtherAgePopupContent: string = undefined;
	public customerOtherAgePopupRedirect: string = undefined;
	// HACK - Around june every year, the calculator fails on 18 years and 66 years, because the API interprets the age for certain coverages faulty
	public hackAgeForApi = 0;

	public constructor(settingsBlock: HtmlBlock) {
		super(settingsBlock);
		this.loadAccidentSettings();
	}

	/**
	 * load settings from CMS if present
	 */
	private loadAccidentSettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');
			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				if (setting.indexOf('work=') > -1) {
					this.work = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('workids=') > -1) {
					this.workIds = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('seniorstep1=') > -1) {
					this.seniorStep1 = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('seniorstep2=') > -1) {
					this.seniorStep2 = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('maxcustomerage=') > -1) {
					this.maxCustomerAge = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('customermaxagepopuptitle=') > -1) {
					this.customerMaxAgePopupTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customermaxagepopupcontent=') > -1) {
					this.customerMaxAgePopupContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customermaxagepopupredirect=') > -1) {
					this.customerMaxAgePopupRedirect = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customerothermaxagepopuptitle=') > -1) {
					this.customerOtherMaxAgePopupTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customerothermaxagepopupcontent=') > -1) {
					this.customerOtherMaxAgePopupContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customerothermaxagepopupredirect=') > -1) {
					this.customerOtherMaxAgePopupRedirect = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customerotheragepopuptitle=') > -1) {
					this.customerOtherAgePopupTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customerotheragepopupcontent=') > -1) {
					this.customerOtherAgePopupContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customerotheragepopupredirect=') > -1) {
					this.customerOtherAgePopupRedirect = this.getSettingsValue(setting_);
				} else if (setting.indexOf('hackageforapi=') > -1) {
					this.hackAgeForApi = parseInt(this.getSettingsValue(setting_));
				}
			});
		} catch (e) {
			exception(e);
		}
	}
}
