import { Options, Vue } from 'vue-class-component';
import {
	UpSaleCampaignData,
	upSaleCampaignName,
	UpSaleProduct,
	updateUpSaleCampaign,
} from '../../services/campaignService';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';
import { mapState } from 'vuex';
import BuyInsuranceSettings from '../../BuyInsuranceSettings';
import { CalculatorCommonModel } from '@/store/modules/calculatorContext';
import {
	PlusCustomerType,
	getUrlProductName,
	plusCustomerDiscount,
	resetCampaignStorageAll,
	uniqueStringArray,
} from '../../services/ProductService';
import { capitalizeFirstLetter } from '../../services/UiStepService';
import { formatPercent, formatNumber } from '../../services/ValidationService';
import store from '@/store/store';
import { getSuggestedProduct } from '../../services/SuggestProductService';

interface Suggestion {
	discount: number;
	plusCustomer: PlusCustomerType;
	suggestProduct: string;
}

@Options({
	name: 'UpSaleComponent',
	components: {},
	props: {},
	computed: mapState<any>({
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class UpSaleComponent extends Vue {
	cms!: BuyInsuranceSettings;

	public showExistingProducts = false;
	public upSaleCampaignData: UpSaleCampaignData = null;
	public upSaleProducts: UpSaleProduct[] = [];
	public commonModel: CalculatorCommonModel = null;
	public showOrderDetails = false;
	public init = false;
	public currentInsurance = '';
	private suggestedProduct: string = null;
	public productList: string[] = [];
	private suggestion: Suggestion = null;
	private existingDiscountAddOn = 0;

	public mounted(): void {
		try {
			this.upSaleCampaignData = store.state.upSaleCampaignData;

			if (!this.upSaleCampaignData) {
				return;
			}
			this.upSaleProducts = this.upSaleCampaignData.upSaleProducts;

			let hasValidUpSaleProduct = false;
			this.upSaleProducts.forEach((product) => {
				if (product.validUpSaleProduct) {
					hasValidUpSaleProduct = true;
				}
				this.currentInsurance += product.productNameDisplay + ' ';
			});
			if (!hasValidUpSaleProduct) {
				return;
			}

			this.commonModel = this.upSaleCampaignData.calculatorCommonModel;

			this.setProductList();

			const floorProperty = this.commonModel.personInfo.floorNr;
			this.suggestion = getSuggestedProduct(this.cms, floorProperty, this.productList);
			if (!this.suggestion.suggestProduct) {
				return;
			}
			this.suggestedProduct = capitalizeFirstLetter(this.suggestion.suggestProduct);

			this.existingDiscountAddOn =
				this.suggestion.discount - plusCustomerDiscount.get(this.upSaleProducts[0].currentLevel);

			this.showExistingProducts = this.upSaleProducts[0].currentLevel !== PlusCustomerType.PK3;

			this.init = true;
		} catch (error) {
			exception(error);
		}
	}

	public suggestText() {
		try {
			let txt: string;

			if (this.suggestion.suggestProduct !== this.cms.multiplePkStartBtnUpSale) {
				txt = this.replaceTokens(this.cms.mainTxtUpSale);
			} else {
				txt = this.cms.mainTxtBasketUpSale;
			}
			txt = this.replaceTokens(txt);

			if (this.showExistingProducts) {
				const multiple = this.upSaleProducts.length > 1;
				let txtProduct = multiple ? this.cms.txtMultipleProductUpSale : this.cms.txtProductUpSale;

				txtProduct = this.replaceTokens(txtProduct);
				txt += txtProduct;
			}
			return txt;
		} catch (error) {
			exception(error);
		}
	}

	private replaceTokens(text: string): string {
		const upSaleCampaignDiscount = this.upSaleCampaignData.upSaleProducts[0].upSaleCampaignDiscount;
		const totalDiscountSuggestedProduct = formatPercent.format(this.suggestion.discount + upSaleCampaignDiscount);

		const saveOnExistingProducts = formatNumber.format(this.getTotalSavingsAmount(this.suggestion.plusCustomer));

		return text
			.replaceAll('{{suggestedProduct}}', this.suggestedProduct)
			.replaceAll('{{totalDiscountSuggestedProduct}}', totalDiscountSuggestedProduct)
			.replaceAll('{{existingDiscountAddOn}}', formatPercent.format(this.existingDiscountAddOn))
			.replaceAll('{{currentInsurance}}', this.currentInsurance)
			.replaceAll('{{saveOnExistingProducts}}', saveOnExistingProducts);
	}

	public getProductHighlights(product: UpSaleProduct) {
		const existingDiscountAddOn = formatPercent.format(this.existingDiscountAddOn);
		const txt = this.cms.singleProductTxtUpSale
			.replaceAll('{{productNameDisplay}}', product.productNameDisplayMobile)
			.replaceAll('{{packageName}}', product.packageName)
			.replaceAll('{{existingDiscountAddOn}}', existingDiscountAddOn);
		return txt;
	}

	public getProductHighlightsAmount(product: UpSaleProduct) {
		const saveOnExistingProduct = this.getSavingsAmount(product, this.suggestion.plusCustomer);
		if (saveOnExistingProduct > 0) {
			const txt = this.cms.productAmountTxtUpSale.replaceAll(
				'{{saveOnExistingProduct}}',
				formatNumber.format(saveOnExistingProduct)
			);
			return txt;
		} else {
			return this.cms.notCalculatedUpSaleProduct;
		}
	}

	public getProductHighlightsSummed() {
		if (this.upSaleProducts.length > 1) {
			const saveOnExistingProducts = formatNumber.format(
				this.getTotalSavingsAmount(this.suggestion.plusCustomer)
			);
			const txt = this.cms.productTotalAmountNumberUpSale.replaceAll(
				'{{saveOnExistingProducts}}',
				saveOnExistingProducts
			);
			return txt;
		}

		return undefined;
	}
	public navigateToProduct() {
		updateUpSaleCampaign(this.suggestion.plusCustomer, this.upSaleCampaignData);
		let url: string;

		resetCampaignStorageAll(this.cms.AllCalculationProducts);
		if (this.suggestion.suggestProduct !== this.cms.multiplePkStartBtnUpSale) {
			url = `\\${getUrlProductName(this.suggestion.suggestProduct)}?campaign=${upSaleCampaignName}`;
		} else {
			url = `\\${this.cms.multiplePkStartUrlUpSale}`;
		}

		store.state.showSpinner = true;
		setTimeout(() => {
			location.href = url;
		}, 200);
	}

	public get btnLabel() {
		return this.cms.btnLabelUpSale + this.suggestedProduct;
	}

	public getSavingsAmount(product: UpSaleProduct, plusCustomer: PlusCustomerType): number {
		const previousPrice = product[product.currentLevel].price;
		const discountPrice = product[plusCustomer].price;
		return previousPrice - discountPrice;
	}

	public getTotalSavingsAmount(plusCustomer: PlusCustomerType): number {
		let totalSavingsAmount = 0;
		this.upSaleProducts.forEach((product) => {
			const productSaving = this.getSavingsAmount(product, plusCustomer);
			if (productSaving > 0) {
				totalSavingsAmount += this.getSavingsAmount(product, plusCustomer);
			}
		});
		return totalSavingsAmount;
	}

	public toggleOrderDetails() {
		this.showOrderDetails = !this.showOrderDetails;
	}

	public setProductList() {
		const products = this.upSaleCampaignData.calculatorCommonModel.personInfo.existingAlmBrandProducts || [];
		products.forEach((name) => {
			this.productList.push(name.trim().toLocaleLowerCase());
		});
		this.upSaleProducts.forEach((product) => {
			this.productList.push(product.productName);
		});

		this.productList = [...new Set(this.productList)];
		this.productList = uniqueStringArray(this.productList);
	}
}
