import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { AccidentSettings } from './AccidentSettings';
import { AccidentModel, STEPS } from './AccidentModel';
import AccidentCalculator from './AccidentCalculator';
import ChoosePackageStepComponent from '../commonSteps/ChoosePackageStepComponent.vue';
import ContactInformationStepComponent from '../commonSteps/ContactInformationStepComponent.vue';
import OverviewStepComponent from '../commonSteps/OverviewStepComponent.vue';
import AdditionalInfoStepComponent from '../commonSteps/AdditionalInfoStepComponent.vue';
import PersonInfoStepComponent from './steps/PersonInfoStepComponent.vue';
import PaymentStepComponent from '../commonSteps/PaymentStepComponent.vue';
import ReceiptStepComponent from '../commonSteps/ReceiptStepComponent.vue';
import CampaignStepComponent from '../commonSteps/CampaignStepComponent.vue';
import { PropType } from 'vue';
import { CardBlock, HtmlBlock, UsabillaInPageBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import { CALCULATOR_LOADED, INIT_CALCULATOR, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import { gotoAddInsurances, nextStep, setFirstCard } from '../services/NavigationService';
import { resetSelectedCalculation, subscribeBasketChange } from '../services/CalculationService';
import {
	getShowReceipt,
	handleValidCustomerAge,
	isValidAdditionalInfo,
	isValidContactInfo,
	isValidOtherPersonInfo,
	isValidPackage,
	isValidPaymentInfo,
	isValidPersonInfo,
	showValidNowOption,
	Validator,
} from '../services/ValidationService';
import { getSubtitle, setDetailsYearlyPrice, setHighLights } from '../services/ProductService';
import { modalAlert, togglePopup } from '../services/AlertService';
import { initComponent, STEP } from '../services/UiStepService';
import MapOrderDescription from './MapOrderDescription';
import { CalculatorUiComponent } from '../BuyInsuranceComponent';

@Options({
	name: 'BuyInsuranceAccidentComponent',
	props: {
		contentBlocks: Object as PropType<Array<CardBlock>>,
		cardReceipt: Object as PropType<CardBlock>,
		settingsBlock: Object as PropType<HtmlBlock>,
		usabillaBlock: Object as PropType<UsabillaInPageBlock>,
		contentUrl: String, // Used by service to handle browser history
		headerBadgeTxt: String,
		hasBadgeText: Boolean,
		calculatorInfoBlock: Object as PropType<Array<HtmlBlock>>,
	},
	components: {
		UsabillaInPageComponent,
		PersonInfoStepComponent,
		ChoosePackageStepComponent,
		OverviewStepComponent,
		AdditionalInfoStepComponent,
		ContactInformationStepComponent,
		PaymentStepComponent,
		ReceiptStepComponent,
		CampaignStepComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class BuyAccident extends Vue implements CalculatorUiComponent {
	contentBlocks: Array<CardBlock>;
	cardReceipt: CardBlock;
	settingsBlock: HtmlBlock;
	usabillaBlock: UsabillaInPageBlock;
	contentUrl: string; // Used by service to handle browser history
	headerBadgeTxt: string;
	hasBadgeText: boolean;
	calculatorInfoBlock: HtmlBlock;

	// list and order of steps
	public steps: Array<string> = STEPS;

	public model!: AccidentModel; // model from store
	public cms!: AccidentSettings; // setting from store
	public calculator: AccidentCalculator = null;
	public componentInit = false;
	public checkout = false;
	private mapOrderDescription: MapOrderDescription;
	private unsubScribeBasketChange: Function;

	public async created() {
		window.scrollTo(0, 0);
		if (!store.getters.getActiveCalculator) {
			// this is first load - update store
			const cms = new AccidentSettings(this.settingsBlock);
			const model: AccidentModel = new AccidentModel();
			if (cms.mockData) {
				AccidentModel.mock(model);
			}
			await store.dispatch(INIT_CALCULATOR, {
				cms,
				model,
			});
		} else {
			await store.dispatch(CALCULATOR_LOADED);
		}

		if (!(await initComponent(this))) {
			return;
		}

		this.calculator = new AccidentCalculator(this);
		setFirstCard(this);
	}

	public mounted(): void {
		this.unsubScribeBasketChange = subscribeBasketChange(this);
	}

	beforeUnmount() {
		if (this.unsubScribeBasketChange) {
			this.unsubScribeBasketChange();
		}
	}

	// called by service
	public addResetSubscription() {
		const unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === RESET_CALCULATORS) {
				setTimeout(() => {
					this.checkout = true;
					store.dispatch(INIT_CALCULATOR, {
						cms: this.cms,
						model: new AccidentModel(),
					});
					unsubscribe();
				}, 2000);
			}
		});
	}

	public get showValidNowOption() {
		return showValidNowOption(this.model);
	}

	public async gotoCard(cardName: string): Promise<boolean> {
		if (cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
			resetSelectedCalculation(this.model);
			// altid kun en risk (dummy)
			this.model.choosePackage.ownRiskId = this.model.calculation.abCalc.excessIdDefault;
		}

		if (cardName === STEP.PACKAGE) {
			if (!this.checkCustomerAge()) {
				this.gotoCard(STEP.PERSON_INFO);
				return false;
			}
			this.calculator.setupExcessList();
		}

		if (cardName === STEP.PAYMENT) {
			// force user to choose yearly/monthly payment to ensure progressive steps
			this.model.choosePackage.monthYear = undefined;
			this.model.payment.userSelected = false;
		}

		this.model.currentCardName = cardName;

		if (cardName === STEP.ORDER) {
			store.state.showSpinner = true;
			if (!this.isValid(this.model.currentCardName)) {
				store.state.showSpinner = false;
				return false;
			}
			this.mapOrderDescription = new MapOrderDescription(this);
			await this.mapOrderDescription.orderByEmail();
			return false;
		}
		return true;
	}

	private checkCustomerAge(): boolean {
		const otherCustomer = this.model.personInfo.otherCustomerAge !== undefined;
		const customerAge = otherCustomer ? this.model.personInfo.otherCustomerAge : this.model.personInfo.customerAge;

		let ok = false;
		if (!otherCustomer) {
			ok = handleValidCustomerAge(this.model, this.cms);
		} else {
			ok = this.handleValidOtherCustomerAge();
		}
		if (!ok) {
			return false;
		}

		if (this.model.personInfo.customerAgeChecked) {
			return true;
		}

		ok = parseInt(customerAge) < this.cms.maxCustomerAge;
		if (!ok) {
			const modalRedirect = otherCustomer
				? this.cms.customerOtherMaxAgePopupRedirect
				: this.cms.customerMaxAgePopupRedirect;

			const modal = modalAlert();
			modal.title = otherCustomer ? this.cms.customerOtherMaxAgePopupTitle : this.cms.customerMaxAgePopupTitle;
			modal.content = otherCustomer
				? this.cms.customerOtherMaxAgePopupContent
				: this.cms.customerMaxAgePopupContent;
			modal.redirect = modalRedirect;
			modal.btnSecondLabel = modalRedirect ? 'Afslut' : 'Ok';
			modal.id = 'customerMaxAgePopup';

			this.model.personInfo.customerAgeChecked = true;

			togglePopup(this.cms, modal);
		}
		// clean customer age input

		this.model.personInfo.customerAge = this.model.personInfo.customerAge.trim().replace(/\D/g, '');
		return ok;
	}

	private handleValidOtherCustomerAge(): boolean {
		const ok = Validator.isValidAge(this.model.personInfo.otherCustomerAge);
		if (!ok) {
			const modal = modalAlert();
			modal.title = this.cms.customerOtherAgePopupTitle;
			modal.content = this.cms.customerOtherAgePopupContent;
			modal.id = 'customerOtherAgePopup';

			togglePopup(this.cms, modal);
		}
		// clean customer age input
		this.model.personInfo.otherCustomerAge = this.model.personInfo.otherCustomerAge.trim().replace(/\D/g, '');
		return ok;
	}

	/**
	 * when nextbtn is clicked - check for valid
	 * @param cardName
	 */
	public nextStep(cardName: string, addToHistory = true) {
		nextStep(cardName, addToHistory, this);
	}

	public getSubtitle(cardName: string) {
		return getSubtitle(cardName, this.model);
	}

	public isActiveCard(cardName: string): boolean {
		return cardName === this.model.currentCardName;
	}

	public isValid(cardName): boolean {
		switch (cardName) {
			case STEP.PERSON_INFO:
				if (store.getters.getActiveCalculatorInx > 0) {
					if (this.model.personInfo.otherName) {
						const res = this.model.personInfo.otherName.match(/^[a-zA-Z\æøå\s]+/gi);
						if (res && res[0]?.length > 1) {
							this.model.personInfo.otherName = res[0];
						}
					}
					return this.model.personInfo.workId && isValidOtherPersonInfo(this.model);
				}
				return this.model.personInfo.workId && isValidPersonInfo(this.model);
			case STEP.PACKAGE:
				return isValidPackage(this);
			case STEP.OVERVIEW:
				return isValidPackage(this);
			case STEP.CONTACT_INFORMATION:
				const ok = isValidContactInfo(this.model, this.cms);
				if (ok) {
					if (this.model.personInfo.otherCustomerAge) {
						return Validator.isValidCpr(this.model.personInfo.otherCpr);
					}
				}
				return ok;
			case STEP.ADDITIONAL_INFO:
				return isValidAdditionalInfo(false, this.model, this.cms);
			case STEP.PAYMENT:
				return isValidPaymentInfo(this.model, this.cms);
			default:
				return true;
		}
	}

	// called by service
	public setOverviewData() {
		setHighLights(this.model);
		if (this.model.personInfo.isMainPerson) {
			this.model.overview.details = [
				'Gælder dig',
				this.model.personInfo.customerAge + ' år',
				this.model.personInfo.zipCode + ', ' + this.model.personInfo.zipName,
				this.model.personInfo.work,
			];
		} else {
			this.model.overview.details = [
				this.model.personInfo.otherName,
				this.model.personInfo.otherCustomerAge + ' år',
				this.model.personInfo.work,
			];
		}
		setDetailsYearlyPrice(this.model, this.cms);
	}

	public gotoAddInsurances(step: string) {
		gotoAddInsurances(step, this);
	}
	public get showReceipt() {
		return getShowReceipt(this);
	}

	public getCardTitle(card: CardBlock): string {
		if (card.name === STEP.PACKAGE && this.model.choosePackage.selectedPackage !== undefined) {
			return this.cms.calculatedHeadline;
		}
		if (store.getters.getActiveCalculatorInx > 0) {
			if (card.name === STEP.PERSON_INFO) {
				return 'Om person';
			}
			if (card.name === STEP.PACKAGE) {
				return 'Pris';
			}
		}

		return card.title;
	}
	/** Called by service */
	public buildDescriptionForEmail(desc: string) {
		return this.mapOrderDescription.buildDescriptionForEmail(desc);
	}
}
