import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pb-3" }
const _hoisted_2 = {
  key: "carOwnerYearsLabel",
  class: "input__label",
  textContent: 'Antal år med egen bil'
}
const _hoisted_3 = { class: "button-container text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressSearchComponent = _resolveComponent("AddressSearchComponent")!
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!
  const _component_SelectModalComponent = _resolveComponent("SelectModalComponent")!
  const _component_ExistingAbProductsComponent = _resolveComponent("ExistingAbProductsComponent")!
  const _component_ButtonTapComponent = _resolveComponent("ButtonTapComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AddressSearchComponent, {
        id: "address",
        key: "address",
        elementId: "address",
        isOnlyZipSearch: false,
        label: "Adresse",
        placeholder: "F.eks Gadenavn 29, 2.tv...",
        defaultValue: _ctx.model.personInfo.address || _ctx.defaultAddress,
        isValid: _ctx.model.personInfo.address !== undefined,
        readonlyValue: _ctx.readonlyAddress,
        visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
        onAddressSearch: _ctx.addressSearch
      }, null, 8, ["defaultValue", "isValid", "readonlyValue", "visibleError", "onAddressSearch"])
    ]),
    _createVNode(_TransitionGroup, { name: "slide-simple" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_inputFieldComponent, {
          class: "text-left pb-3",
          key: "customerAge",
          id: "customerAge",
          name: "customerAge",
          placeholder: "F.eks. 42 ",
          label: "Din alder",
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          readonlyValue: _ctx.readonlyAge,
          visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
          innerData: _ctx.model.personInfo,
          required: true,
          onInputChanged: _ctx.setCarOwnerArray
        }, null, 8, ["validateOnLoad", "readonlyValue", "visibleError", "innerData", "onInputChanged"]), [
          [_vShow, _ctx.model.personInfo.address && !_ctx.readonlyAge]
        ]),
        (_ctx.model.personInfo.customerAge && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge))
          ? (_openBlock(), _createElementBlock("label", _hoisted_2))
          : _createCommentVNode("", true),
        (
					_ctx.model.personInfo.address &&
					_ctx.model.personInfo.customerAge &&
					_ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge)
				)
          ? (_openBlock(), _createBlock(_component_SelectModalComponent, {
              key: _ctx.updateCarOwnerYears,
              id: "carOwnerYears",
              name: "carOwnerYears",
              preSelect: _ctx.readonlyCarOwnerYears || _ctx.model.carInfo.carOwnerYears,
              innerData: _ctx.model.carInfo.carOwnerYearsByAgeArray,
              selected: _ctx.model.carInfo.carOwnerYears !== undefined,
              placeholder: "Vælg venligst",
              visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
              errorMsgOverride: "Du skal vælge hvor lang tid du har haft bil",
              readonlyValue: _ctx.readonlyCarOwnerYears,
              "onSelectModalComponent:selected": _ctx.selectCarOwnerYears
            }, null, 8, ["preSelect", "innerData", "selected", "visibleError", "readonlyValue", "onSelectModalComponent:selected"]))
          : _createCommentVNode("", true),
        (
					_ctx.model.showExistingAlmBrandProducts &&
					_ctx.model.personInfo.customerAge &&
					_ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge) &&
					_ctx.model.carInfo.carOwnerYears
				)
          ? (_openBlock(), _createBlock(_component_ExistingAbProductsComponent, {
              key: "ExistingAbProductsComponent",
              class: "pt-3",
              card: _ctx.card
            }, null, 8, ["card"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ButtonTapComponent, {
        class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', { 'btn-disabled': !_ctx.isValid }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
        textContent: _toDisplayString(_ctx.card.linkText),
        "data-gtm": _ctx.cms.dataGtm,
        "data-gtm-click-text": _ctx.card.routePath
      }, null, 8, ["class", "textContent", "data-gtm", "data-gtm-click-text"])
    ])
  ]))
}