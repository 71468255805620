import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import localforage from 'localforage';
import { capitalizeFirstLetter, STEP } from './UiStepService';
import { formatNumber, formatPercent } from './ValidationService';
import { isLocalHost } from '@/views/calculators/services/environmentService';
import { exception, information, warning } from '@/appinsights/logging/ApplicationInsightLog';
import { HeaderCardBlock } from '@/cms/definitions/content-types';
import { headerBadgeShown } from './NavigationService';
import store from '@/store/store';
import AxiosService from './axiosService';
import { defaultConfig } from '@/cms/api/ApiConfig';
import BuyInsuranceSettings from '../BuyInsuranceSettings';

export const enum PlusCustomerType {
	PK0 = 'pk0',
	PK1 = 'pk1',
	PK2 = 'pk2',
	PK3 = 'pk3',
}

export const plusCustomerDiscount: Map<PlusCustomerType, number> = new Map([
	[PlusCustomerType.PK0, 0],
	[PlusCustomerType.PK1, 0.05],
	[PlusCustomerType.PK2, 0.1],
	[PlusCustomerType.PK3, 0.15],
]);

export const PlusCustomerArray = [
	PlusCustomerType.PK0,
	PlusCustomerType.PK1,
	PlusCustomerType.PK2,
	PlusCustomerType.PK3,
];

export const getCalculatorIndex = (name: string): number => {
	const productInx = name.replace(/[^\d]/g, '');
	return productInx === '' ? -1 : parseInt(productInx);
};

export const getShortProductName = (productName: string): string => {
	if (!productName) {
		return '';
	}
	let name = stripProductIndex(productName).toLowerCase();
	const inx = getCalculatorIndex(productName);
	let productInx = '';
	if (inx > 0) {
		productInx = ' ' + (inx + 1); // næste forsikring hedder 2 ikke 1
	}
	switch (name) {
		case InsurancesProductEnum.BIL_FORSIKRING:
		case InsurancesProductEnum.INDBO_FORSIKRING:
		case InsurancesProductEnum.REJSE_FORSIKRING:
		case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
		case InsurancesProductEnum.HUS_FORSIKRING:
		case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
			name = name.replace('forsikring', '') + productInx;
			break;
		case InsurancesProductEnum.ULYKKES_FORSIKRING:
		case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
			name = name.replace('sforsikring', '') + productInx;
			break;
		case InsurancesProductEnum.HUNDE_FORSIKRING:
			name = name.replace('eforsikring', '') + productInx;
			break;
		default:
			name = productName + productInx;
			break;
	}
	return capitalizeFirstLetter(name);
};

export const getCalculatorNameUi = (name: string): string => {
	if (!name) {
		return;
	}
	const n = name.charAt(0).toLocaleUpperCase() + name.slice(1);
	const inx = getCalculatorIndex(n);
	return inx < 1 ? n.replace('' + inx, '') : n.replace('' + inx, ' ' + (inx + 1)); // næste forsikring hedder 2 ikke 1
};

export const getCoverageNames = (selectedPackage) => {
	if (selectedPackage.expandedCoverages) {
		return selectedPackage.expandedCoverages.join(', ');
	}

	const names = [];
	selectedPackage.coverages.forEach((element) => {
		names.push(element.name || element.title);
	});
	return names.join(', ');
};

export const setHighLights = (model) => {
	model.overview.highlights = [];
	const isPlusCustomer = model.calculation.discount && model.calculation.discount < 1;

	let hasCampaignDiscount = false;
	let campaignDiscount = 0;
	let discountDisplay: string = undefined;
	let hasCampaignSplash = false;
	if (model.campaign.valid) {
		hasCampaignDiscount = model.campaign.discount > 0;
		if (hasCampaignDiscount) {
			campaignDiscount = model.campaign.discount;
			discountDisplay = model.campaign.discountDisplay;
		} else {
			hasCampaignDiscount = model.campaign.tiaDiscount > 0;
			if (hasCampaignDiscount) {
				campaignDiscount = model.campaign.tiaDiscount;
				discountDisplay = formatNumber.format(100 * campaignDiscount) + ' %';
			}
		}
		hasCampaignSplash = model.campaign.splash;
	}

	if (isPlusCustomer) {
		let txt = '<div class="grow"><i>Plus</i>Kunde rabat fratrukket pris </div><div>';
		if (hasCampaignDiscount) {
			txt = '<div class="grow"><i>Plus</i>Kunde rabat </div><div>';
		}
		model.overview.highlights.push(`${txt}<div>${model.calculation.discountDisplay}</div>`);
	}
	if (hasCampaignDiscount) {
		let txt = '<div class="grow">Online rabat fratrukket pris </div>';
		if (isPlusCustomer) {
			txt = '<div class="grow">Online rabat </div>';
		}
		model.overview.highlights.push(`${txt}<div>${discountDisplay}</div>`);
	}
	if (isPlusCustomer && hasCampaignDiscount) {
		const totalDiscount = Math.abs(model.calculation.discount - 1) + campaignDiscount;
		const display = formatPercent.format(totalDiscount);
		model.overview.highlights.push(
			`<div class="grow">Samlet rabat fratrukket pris </div><div><strong>${display}</strong></div>`
		);
	}

	if (hasCampaignSplash) {
		model.overview.highlights.push(`<div class="grow">Kampagne </div><div>${model.campaign.splash}</div>`);
	}
};
export const setDetailsYearlyPrice = (model, cms) => {
	if (!cms.overviewShowYearlyPrice) {
		// wait for all calcs
		let max = 20;
		const interval = setInterval(() => {
			max--;
			if (model.choosePackage.selectedPackage.yearlyPriceTotalDisplay || max < 1) {
				model.overview.details = [
					'Årlig pris: ' + model.choosePackage.selectedPackage.yearlyPriceTotalDisplay,
					...model.overview.details,
				];
				clearInterval(interval);
			}
		}, 500);
	}
};

export const getCardTitle = (card, model, cms): string => {
	return card.name === STEP.PACKAGE && model.choosePackage.selectedPackage !== undefined
		? cms.calculatedHeadline
		: card.title;
};

export const getSubtitle = (cardName: string, model) => {
	switch (cardName) {
		case STEP.CAR_INFO:
			return model.carInfo.subtitle;
		case STEP.DOG_INFO:
			return model.dogInfo.subtitle;
		case STEP.CHILDREN_INFO:
			return model.familyInfo.subtitle;
		case STEP.PERSON_INFO:
			return model.personInfo.subtitle;
		case STEP.OVERVIEW:
			return model.overview.subtitle;
		case STEP.ADDITIONAL_INFO:
			return model.additionalInfo.subtitle;
		case STEP.PACKAGE:
			return model.choosePackage.subtitle;
	}
	return model[cardName] ? model[cardName].subtitle : undefined;
};

export const stripProductIndex = (productName: string) => {
	return productName.replace(/\d/g, ''); // remove indexes (bilforsikring0 -> bilforsikring, bilforsikring1 -> bilforsikring)
};

export const getUrlProductName = (productName) => {
	const name = stripProductIndex(productName);

	switch (name) {
		case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
			return 'boerneulykkesforsikring';
		case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
			return 'boernefamilieforsikring';
		default:
			return name;
	}
};

// cleanup campaign in storage if it exists
export const resetCampaignStorage = (productName: string) => {
	try {
		const campaignName = 'campaign' + productName;
		localforage.removeItem(campaignName);
	} catch (e) {
		// no problem
	}
};

// cleanup all campaigns in storage if they exists
export const resetCampaignStorageAll = (products: string[]) => {
	products.forEach((product) => {
		resetCampaignStorage(product);
	});
};

//update excesses from calculation result
export const updateExcessesFromCalculation = (response: any, calcConfig: any): boolean => {
	try {
		if (isLocalHost) {
			information('updateExcessesFromCalculation');
		}
		const excesses: Array<{ id: number; amount: number }> = response?.data?._embedded?.excesses;
		if (excesses?.length > 0) {
			excesses.forEach((excess) => {
				if (Number.isNaN(excess.amount)) {
					warning('update excess isNAN', excess);
					return;
				}

				calcConfig.excessList.forEach((excessCurrent, inx) => {
					// car excess Ids are strings!
					if (excess.id + '' === excessCurrent.id + '') {
						if (isLocalHost) {
							information('updating', {
								excessCurrentId: excessCurrent.id,
								excessCurrentAmount: excessCurrent.amount,
							});
						}
						if (isLocalHost || excess.amount !== calcConfig.excessList[inx].amount) {
							warning('update excess', { excess, excessList: calcConfig.excessList[inx] });
							calcConfig.excessList[inx].amount = excess.amount;
						}

						if (isLocalHost) {
							information('updated', {
								excessId: calcConfig.excessList[inx].id,
								excessAmount: calcConfig.excessList[inx].amount,
							});
						}
					}
				});
			});
			return true;
		}
	} catch (e) {
		exception(e);
		// Too bad
	}
	return false;
};

export const setHeaderCardBlock = (block: HeaderCardBlock, uiComp) => {
	const headerBlock = block;
	const text = headerBlock.text && headerBlock?.text?.trim() === '' ? '' : headerBlock.text;
	const hasCss = headerBlock.css && headerBlock.css?.trim() !== '';

	const isHeaderBadgeShown = headerBadgeShown(block.contentId);
	if (!isHeaderBadgeShown) {
		addHeaderParts(headerBlock, text, hasCss, uiComp);
	}
};

const addHeaderParts = (headerBlock: HeaderCardBlock, text, hasCss, uiComp) => {
	if (text !== '') {
		uiComp.headerBadgeTxt = uiComp.headerBadgeTxt ? uiComp.headerBadgeTxt + text : text;
		uiComp.hasBadgeText = true;
	}
	if (hasCss) {
		uiComp.headerBadgeTxt = uiComp.headerBadgeTxt ? uiComp.headerBadgeTxt + headerBlock.css : headerBlock.css;
	}

	if (headerBlock.script) {
		const s = document.createElement('script');
		s.type = 'text/javascript';
		try {
			s.appendChild(document.createTextNode(headerBlock.script));
			document.body.appendChild(s);
		} catch (e) {
			exception(e);
		}
	}
};
// filter our multiple entries in array
export const uniqueStringArray = (array: string[]): string[] => {
	return [...new Set(array)] as string[];
};

// Get consent text and map brand
export const initConsent = async (cms: BuyInsuranceSettings) => {
	if (!store.state.consent.text) {
		// fallBack
		store.state.consent = {
			text: cms.consentText,
			detailsText: cms.consentDetailsText !== '' ? cms.consentDetailsText : undefined,
			linkText: cms.consentLinkText !== '' ? cms.consentLinkText : undefined,
		};
		// Get consent and map brand
		//https://almbrand--qamulti.sandbox.my.salesforce.com/services/apexrest/consentList?businessBrand=ALMBRAND
		// const axiosService = new AxiosService();
		// const res = await axiosService.get(
		// 	defaultConfig.baseUrl +
		// 		store.state.openServiceCalculatorUrl +
		// 		'/services/apexrest/consentList?businessBrand=ALMBRAND'
		// );
	}
};
