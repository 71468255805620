import { HtmlBlock } from '@/cms/definitions/content-types';
import BuyInsuranceSettings from '../BuyInsuranceSettings';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

export class DogSettings extends BuyInsuranceSettings {
	public manyDogsContentWarning: string;
	public manyDogsTitleWarning: string;
	public dogList: Array<string> = [];
	public dogListIds: Array<string> = [];
	public dogCountlabel: string;
	public questionVaccineTxt: string;
	public questionVaccineMultiTxt: string;
	public questionHealthyTxt: string;
	public questionHealthyMultiTxt: string;
	public questionBeenUnhealthyTxt: string;
	public questionBeenUnhealthyMultiTxt: string;
	public questionVetTxt: string;
	public questionVetMultiTxt: string;
	public vaccinePopTitle: string;
	public vaccinePopContent: string;
	public healthyPopTitle_: string;
	public healthyPopContent_: string;
	public beenUnhealthyPopTitle: string;
	public beenUnhealthyPopContent: string;
	public vetPopTitle: string;
	public vetPopContent: string;

	public vaccinePopContentMulti: string;
	public healthyPopContent_Multi: string;
	public beenUnhealthyPopContentMulti: string;
	public vetPopContentMulti: string;

	public constructor(settingsBlock: HtmlBlock) {
		super(settingsBlock);
		this.loadAccidentSettings();
	}

	/**
	 * load settings from CMS if present
	 */
	private loadAccidentSettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');
			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				if (setting.indexOf('manydogscontentwarning=') > -1) {
					this.manyDogsContentWarning = this.getSettingsValue(setting_);
				} else if (setting.indexOf('manydogstitlewarning=') > -1) {
					this.manyDogsTitleWarning = this.getSettingsValue(setting_);
				} else if (setting.indexOf('doglist=') > -1) {
					this.dogList = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('doglistids=') > -1) {
					this.dogListIds = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('dogcountlabel=') > -1) {
					this.dogCountlabel = this.getSettingsValue(setting_);
				} else if (setting.indexOf('questionvaccinetxt=') > -1) {
					this.questionVaccineTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('questionvaccinemultitxt=') > -1) {
					this.questionVaccineMultiTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('questionhealthytxt=') > -1) {
					this.questionHealthyTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('questionhealthymultitxt=') > -1) {
					this.questionHealthyMultiTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('questionbeenunhealthytxt=') > -1) {
					this.questionBeenUnhealthyTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('questionbeenunhealthymultitxt=') > -1) {
					this.questionBeenUnhealthyMultiTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('questionvettxt=') > -1) {
					this.questionVetTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('questionvetmultitxt=') > -1) {
					this.questionVetMultiTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('vaccinepoptitle=') > -1) {
					this.vaccinePopTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('vaccinepopcontent=') > -1) {
					this.vaccinePopContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('healthypoptitle_=') > -1) {
					this.healthyPopTitle_ = this.getSettingsValue(setting_);
				} else if (setting.indexOf('healthypopcontent_=') > -1) {
					this.healthyPopContent_ = this.getSettingsValue(setting_);
				} else if (setting.indexOf('beenunhealthypoptitle=') > -1) {
					this.beenUnhealthyPopTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('beenunhealthypopcontent=') > -1) {
					this.beenUnhealthyPopContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('vetpoptitle=') > -1) {
					this.vetPopTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('vetpopcontent=') > -1) {
					this.vetPopContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('vaccinepopcontentmulti=') > -1) {
					this.vaccinePopContentMulti = this.getSettingsValue(setting_);
				} else if (setting.indexOf('healthypopcontent_multi=') > -1) {
					this.healthyPopContent_Multi = this.getSettingsValue(setting_);
				} else if (setting.indexOf('beenunhealthypopcontentmulti=') > -1) {
					this.beenUnhealthyPopContentMulti = this.getSettingsValue(setting_);
				} else if (setting.indexOf('vetpopcontentmulti=') > -1) {
					this.vetPopContentMulti = this.getSettingsValue(setting_);
				}
			});
		} catch (e) {
			exception(e);
		}
	}
}
