import BuyHouse from './BuyHouseComponent';
import AxiosService from '@/views/calculators/services/axiosService';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { SelectableData, UPDATE_SELECTABLE } from '@/store/modules/selectableContext';
import store from '@/store/store';
import { REMOVE_CALCULATOR } from '@/store/modules/calculatorContext';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	callAllCalculations,
	getRisksForPackage,
	mapCoveragesFromProductConfig,
	onePackageCalcEpi,
	prepareCalculations,
	setUpExcesses,
	setupCalculator,
	setupPackages,
	updateCalcKeys,
} from '../services/CalculationService';
import { Modal, modalWithExit, togglePopup } from '../services/AlertService';
import { PlusCustomerType, updateExcessesFromCalculation } from '../services/ProductService';
import { HouseSettings } from './HouseSettings';
import { HouseModel } from './HouseModel';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';
import { Calculator } from '../BuyInsuranceComponent';
import { spinnerThreshold } from '../services/environmentService';

export default class HouseCalculator implements Calculator {
	public model: HouseModel; // the data structure for state and properties
	public cms: HouseSettings; // settings from EPI
	private buyHouse: BuyHouse; // component using calculator
	public calcConfig: any;
	public successCalc = true;
	public axiosService: AxiosService;
	private calculatorUrl: string;
	private calculatorUrlOther: string;
	public calculatorInfo: Array<any>;

	constructor(buyHouse: BuyHouse) {
		this.buyHouse = buyHouse;
		this.model = buyHouse.model;
		this.cms = buyHouse.cms;
		this.calculatorInfo = JSON.parse(buyHouse.calculatorInfoBlock.markup);

		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + this.cms.calculatorUrl; // either holiday or house.
		this.calculatorUrlOther =
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + this.cms.calculatorUrlOther; // either holiday or house.
		this.axiosService = new AxiosService(this.calculatorUrl);
		this.setUpConfig();
	}

	public async setUpConfig() {
		setupCalculator(this, this.calculatorInfo);

		if (this.model.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING) {
			this.cms.insuredValue = this.calcConfig.insuredValue;
		}

		setupPackages(this.model, this.cms, this.calcConfig);

		mapCoveragesFromProductConfig(this.model, this.cms);
		updateCalcKeys(this.model);
	}

	public setupExcessList() {
		setUpExcesses(this.model, this.calcConfig.excessList);
	}

	public async getCalculations(excessId?: number): Promise<boolean> {
		const selectedExcessId = excessId || this.model.choosePackage.ownRiskId;
		prepareCalculations(this.model, this.cms, true, selectedExcessId);
		return await callAllCalculations(this, selectedExcessId, true, 'House calculator');
	}

	public async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {
		return await onePackageCalcEpi(this, monthly, excessId, packageId, true, 'House/holiday house calculator');
	}

	public async checkForValidAddress(): Promise<boolean> {
		try {
			let gotoOtherCalculator = false;
			const packageId = this.model.calculation.abCalc.packageIds[0];
			const excessId = this.model.calculation.abCalc.excessIdDefault;

			store.state.showSpinner = true;
			const response = await this.validateAddress(excessId, packageId);

			if (response?.status === 200) {
				if (!this.model.updatedExcessListFromProduct) {
					updateExcessesFromCalculation(response, this.calcConfig);
					this.setupExcessList();
					this.model.updatedExcessListFromProduct = true;
				}
				return Promise.resolve(true);
			}

			if (response?.status === 400) {
				// check if calculation will work for other calculator either holiday or house.
				const responseOther = await this.checkOtherHouseType();
				gotoOtherCalculator = responseOther.status === 200;
			}

			if (!response || response?.status !== 200) {
				let modal = modalWithExit(this.cms);
				modal.id = 'calcWarning';
				modal.track = true;
				modal.trackToken = `error/invalidAddress`;

				if (gotoOtherCalculator && response.status === 400) {
					modal = this.handleCalculatorChange();
				} else if (!gotoOtherCalculator && response.data?.warningMessage) {
					modal.content = response.data.warningMessage.replace(/\[[^\]]*?\]/g, '').trim(); // remove error code from backend e.g. [BR2]
				}

				togglePopup(this.cms, modal);
				return Promise.resolve(false);
			}

			return Promise.resolve(true);
		} catch (error) {
			exception(error);
			return Promise.resolve(false);
		} finally {
			store.state.showSpinner = false;
		}
	}

	private async validateAddress(excessId, packageId) {
		let response;
		try {
			store.state.showSpinner = true;
			// Spinner on load is false when personInfo card is not required
			spinnerThreshold(this.cms);
			const params = this.createParamsEpi(excessId, packageId, 1);
			response = await this.axiosService.getQuery(this.calculatorUrl, params);
			if (!response || response?.status === 500) {
				// try again, cannot use axiosRetry, since error code 400 is OK
				response = await this.axiosService.getQuery(this.calculatorUrl, params);
				if (response?.status === 500) {
					// try again, cannot use axiosRetry, since error code 400 is OK
					response = await this.axiosService.getQuery(this.calculatorUrl, params);
				}
			}
		} catch (e) {
			exception(e);
		}
		return response;
	}

	private async checkOtherHouseType() {
		try {
			store.state.showSpinner = true;
			let otherProduct: string;
			if (this.model.productName === InsurancesProductEnum.HUS_FORSIKRING) {
				otherProduct = InsurancesProductEnum.FRITIDSHUS_FORSIKRING;
			} else {
				otherProduct = InsurancesProductEnum.HUS_FORSIKRING;
			}

			const params = this.createParamsEpi(
				this.cms.excessIdOther,
				this.cms.basePackageOther,
				1,
				this.cms.selectedRisksOther,
				otherProduct
			);
			const responseOther = await this.axiosService.getRetry(this.calculatorUrlOther, params);
			return responseOther;
		} catch (e) {
			exception(e);
		}
	}

	private handleCalculatorChange(): Modal {
		let address = this.model.personInfo.address;
		if (InsurancesProductEnum.FRITIDSHUS_FORSIKRING === this.model.productName) {
			address = this.model.personInfo.holidayAddress.address;
		}

		const customerAge = this.model.personInfo.customerAge;
		const almbrandCustomer = this.model.personInfo.almbrandCustomer;
		const gotoProduct =
			InsurancesProductEnum.HUS_FORSIKRING === this.model.productName
				? InsurancesProductEnum.FRITIDSHUS_FORSIKRING
				: InsurancesProductEnum.HUS_FORSIKRING;
		const existingAlmBrandProducts: Array<string> = this.model.personInfo.existingAlmBrandProducts.filter(
			(product) => product.toLocaleLowerCase() !== gotoProduct
		);
		const query = {
			step: 'person_info',
			address,
			customerAge,
			almbrandCustomer,
			existingAlmBrandProducts,
			product: gotoProduct,
		};

		const modal = modalWithExit(this.cms);
		modal.id = 'changeHouseType';
		modal.title = this.cms.changeCalcTitle;
		modal.content = this.cms.changeCalcContent;
		modal.btnInlineLabel = this.cms.btnInlineLabel;
		modal.btnInlineStyle = this.cms.btnInlineStyle;
		modal.btnInlineAction = () => {
			// remove this from basket if there is only one product in basket
			this.removeFromBasket();
			// reset this personInfo
			this.model.personInfo = {
				subtitle: undefined,
				customerAge: undefined,
				address: undefined,
				zipCode: undefined,
				zipName: undefined,
				streetName: undefined,
				houseNr: undefined,
				floorNr: undefined,
				doorNr: undefined,
				kvhxValue: undefined,
				almbrandCustomer: undefined,
				existingAlmBrandProducts: [],
				holidayAddress: {
					address: undefined,
					zipCode: undefined,
					zipName: undefined,
					streetName: undefined,
					houseNr: undefined,
					floorNr: undefined,
					doorNr: undefined,
					kvhxValue: undefined,
				},
			} as any;

			// KLK remove ref to buyHouse
			this.buyHouse.$router.push({
				path: 'gotoproduct',
				query,
			});
			togglePopup(this.cms, { id: 'changeHouseType', show: false }); // close popup
			setTimeout(() => {
				store.dispatch(REMOVE_CALCULATOR, this.model.productName);
			}, this.cms.initialTimeout);
		};
		return modal;
	}
	private async removeFromBasket() {
		const selectGroup = await store.getters.getSelectableGroup('basket');
		const prods = selectGroup.filter((product) => product.startsWith('hus') || product.startsWith('fritids'));
		if (prods?.length < 2) {
			// remove this product from basket
			const toggle: SelectableData = {
				group: 'basket',
				id: this.model.productName + '0',
				isSelected: false,
				sortMultiple: true,
			};
			await store.dispatch(UPDATE_SELECTABLE, toggle);
		}
	}

	private createParamsEpi(
		excessId: number,
		packageId: number,
		monthlyPrices: number,
		selectedRisks?: Array<string>,
		forceProductName?: string
	) {
		const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g, ''));
		const selected = selectedRisks ? selectedRisks.toString() : getRisksForPackage(packageId, this.model);
		const productName = forceProductName ? forceProductName : this.model.productName;
		if (productName === InsurancesProductEnum.HUS_FORSIKRING) {
			const streetName = this.model.personInfo.streetName || this.model.personInfo.holidayAddress.streetName;
			const buildingNoWithLetter = this.model.personInfo.houseNr || this.model.personInfo.holidayAddress.houseNr;
			const postalCode = this.model.personInfo.zipCode || this.model.personInfo.holidayAddress.zipCode;
			const params = {
				plusCustomer: PlusCustomerType.PK0.toUpperCase(),
				age: customerAge,
				excessId: `${excessId}`,
				streetName,
				// doorNr: this.model.personInfo.doorNr,
				// floorNr: this.model.personInfo.floorNr,
				buildingNoWithLetter,
				postalCode,
				paymentsPerYear: monthlyPrices === 1 ? 12 : 1,
				selected,

				// kvhxValue: this.model.personInfo.kvhxValue,
				// selectedOptionalProducts: '46,9,8',
				// zipName: `${this.model.personInfo.zipName}`,
				// extendedPackage: `${packageId}`,
			};
			return params;
		} else {
			const streetName = this.model.personInfo.holidayAddress.streetName || this.model.personInfo.streetName;
			const buildingNoWithLetter = this.model.personInfo.holidayAddress.houseNr || this.model.personInfo.houseNr;
			const postalCode = this.model.personInfo.holidayAddress.zipCode || this.model.personInfo.zipCode;
			// console.log('to holiday', this.model.personInfo);

			const params = {
				plus_customer: PlusCustomerType.PK0.toUpperCase(),
				age: customerAge,
				excess_id: `${excessId}`,
				street_name: streetName,
				building_no_with_letter: buildingNoWithLetter,
				postal_code: postalCode,
				payments_per_year: monthlyPrices === 1 ? 12 : 1,
				selected,
				insured_value: this.calcConfig.insuredValue, // only holidayHouse
			};
			return params;
		}
	}
}
