import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/views/calculators/services/environmentService';
import {
	CommonModel,
	Model,
	PersonInfoCommon,
	mockAdditionalInfo,
	mockContactInformation,
	mockPersonInfo,
} from '../types/CommonModel';
import { information } from '@/appinsights/logging/ApplicationInsightLog';
import CarInfoStepComponent from './steps/CarInfoStepComponent';

// list and order of steps
export const STEPS: Array<string> = [
	STEP.CAR_INFO,
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class CarModel extends CommonModel implements Model {
	public constructor() {
		super(STEPS, InsurancesProductEnum.BIL_FORSIKRING);
		this.calculation.updatePrices = ['pr1', 'pr2', 'pr3', 'pr4'];
	}

	public personInfo = new PersonInfoCommon();

	public carInfo = {
		subtitle: <string>undefined,
		car: undefined,
		year: undefined,
		yearlyMileage: undefined,
		licenseplate: undefined,
		carWeight: 0,
		kid: 0,
		fuelType: undefined,
		isElectricHybrid: false,
		variantId: undefined,
		variantName: undefined,
		vehicleGroup: undefined,
		model: undefined,
		brand: undefined,
		displayText: undefined,
		searchBrandModel: false,
		checkForSpecialCar: false,
		isBrandModelSelected: false,
		special: false,
		specialDetected: false,
		useKid: false,
		carOwnerYears: undefined,
		carOwnerYearsByAgeArray: [],
	};

	public static mock(model: CarModel) {
		if (isDevelop) {
			mockPersonInfo(model);

			Object.assign(model.carInfo, {
				subtitle: 'Suzuki Baleno (DM34004)',
				car: 'Suzuki Baleno (DM34004)',
				year: 2018,
				yearlyMileage: 'Op til 15.000 km.',
				licenseplate: 'DM34004',
				carWeight: 948,
				kid: 9000000002636721,
				fuelType: 'Benzin',
				isElectricHybrid: false,
				variantId: '20231784',
				variantName: '1,2 DUALJET 16V ACTIVE 90HK 5D',
				vehicleGroup: 106,
				model: 'Baleno',
				brand: 'Suzuki',
				displayText: 'Suzuki Baleno (DM34004) 2018 1,2 DUALJET 16V ACTIVE 90HK 5D',
				searchBrandModel: false,
				checkForSpecialCar: false,
				isBrandModelSelected: true,
				carOwnerYears: 'Har ikke haft bil før',
				// carOwnerYearsByAgeArray: [],
			});
			mockAdditionalInfo(model);
			Object.assign(model.additionalInfo, {
				existFormerInsurance: 'nej',
				formerInsurance: undefined,
			});

			mockContactInformation(model);
		}
	}
	public static mockLicensePlateSearch(response, uiComp: CarInfoStepComponent): boolean {
		if (isDevelop && uiComp.cms.mockData) {
			if (!response || response?.status !== 200) {
				information('MOCK car search');
				response.data = [
					{
						brand: '<span style="background-color: red;">Suzuki MOCK</span>',
						model: 'Baleno',
						variant: '1,2 DUALJET 16V ACTIVE 90HK 5D',
						special: true,
						group: 106,
						details: {
							weight: 948,
							year: 2018,
							doors: 5,
							transmission: 'Man.',
							horse_power: 90,
							fuel_type: 'Benzin',
							body_style: 'Hatch',
							engine_capacity: 1242,
							detail_text: '2018 948 90 5 Man. Benzin Hatch 1242',
						},
						brandModel: 'Suzuki Baleno',
						empty: false,
						variant_id: 20231784,
						kid: 9000000002636721,
					},
				];
				uiComp.model.carInfo.licenseplate = 'DM34004';
				uiComp.mapCar(response.data[0]);
				uiComp.showLicensePlateStatus = false;
				uiComp.updateLicenseplate += '1';
			}
			return true;
		}
		return false;
	}
}
