import { SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import OverviewCalculatorStepComponent from './OverviewCalculatorStepComponent.vue';
import { AccidentSettings } from '../../accident/AccidentSettings';
import { HomeSettings } from '../../home/HomeSettings';
import { HouseSettings } from '../../house/HouseSettings';
import { DogSettings } from '../../dog/DogSettings';
import { TravelSettings } from '../../travel/TravelSettings';
import { CarSettings } from '../../car/CarSettings';
import { BasketSettings } from '../BasketSettings';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { path } from '../../services/NavigationService';
import { getSuggestText, getSuggestText1, setSuggestText } from '../../services/SuggestProductService';
import { getMonthlyTotalPrice } from '../../services/CalculationService';

@Options({
	name: 'OverviewBasketMiniStepComponent',
	components: {
		OverviewCalculatorStepComponent,
	},
})
export default class OverviewBasketMiniStepComponent extends Vue {
	public cms:
		| AccidentSettings
		| HomeSettings
		| HouseSettings
		| DogSettings
		| TravelSettings
		| CarSettings
		| BasketSettings; // settings from CMS (store)

	public updateProdKey = 'updateProdKey';
	public productNames: Array<string> = [];
	public suggest: { suggestedProducts: string; discountDisplay: string } = {
		suggestedProducts: undefined,
		discountDisplay: undefined,
	};
	public suggestText: string = null;
	public suggestText1: string = null;
	public hasPrices = false;
	private unsubscribe: Function;

	public beforeUnmount() {
		this.unsubscribe();
	}

	public async created() {
		this.cms = store.getters.getActiveCalculator?.cms || store.getters.getBasket?.cms;
		this.productNames = await store.getters.getSelectableGroup('basket');
		this.unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
				this.productNames = store.getters.getSelectableGroup('basket');
				this.updateProdKey += '1';
				this.setSuggestText();
			}
		});
		this.setSuggestText();
	}

	private setSuggestText() {
		setSuggestText(this, this.cms);
	}
	public getSuggestText(): string {
		return getSuggestText(this, this.cms);
	}
	public getSuggestText1(): string {
		return getSuggestText1(this, this.cms);
	}

	public get showButtons(): boolean {
		// don't show when on basketPage
		return !this.$route.path.toLocaleLowerCase().includes('/forsikringer');
	}

	public get forsikringerLink() {
		//const url = urlService.ensureCorrectLink(path + 'forsikringer');
		const url = path + 'forsikringer';
		return url;
	}

	public get basketLink() {
		// const url = urlService.ensureCorrectLink(path + 'gotoproduct?product=kurv');
		const url = path + 'gotoproduct?product=kurv';
		return url;
	}

	public getMonthlyTotalPrice(monthly: boolean): string {
		return getMonthlyTotalPrice(monthly, this, this.productNames);
	}
}
