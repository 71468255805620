import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/views/calculators/services/environmentService';
import {
	CommonModel,
	Model,
	PersonInfoHomeFamily,
	mockAdditionalInfo,
	mockContactInformation,
	mockPersonInfo,
} from '../types/CommonModel';
// list and order of steps
export const STEPS: Array<string> = [
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class HomeModel extends CommonModel implements Model {
	public constructor() {
		super(STEPS, InsurancesProductEnum.INDBO_FORSIKRING);
	}

	public personInfo = new PersonInfoHomeFamily();

	public static mock(model: HomeModel) {
		if (isDevelop) {
			mockPersonInfo(model);
			Object.assign(model.personInfo, {
				familyMemberCount: '4 personer',
				familyMemberCountId: 4,
				thatchedRoof: 'ja',
			});

			mockAdditionalInfo(model);

			mockContactInformation(model);
		}
	}
}
