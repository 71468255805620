import { mapState } from 'vuex';
import FieldValidator from '@/views/calculators/services/fieldValidatorService';
import { CardBlock } from '@/cms/definitions/content-types';
import { AccidentSettings } from '../accident/AccidentSettings';
import { HomeSettings } from '../home/HomeSettings';
import { HouseSettings } from '../house/HouseSettings';
import { DogSettings } from '../dog/DogSettings';
import { Options, Vue } from 'vue-class-component';
import { TravelSettings } from '../travel/TravelSettings';
import { jaNejArray, Validator, validNowArray, ValuePair } from '../services/ValidationService';
import { getNextCardLinkText } from '../services/NavigationService';
import { handleAdditionalStepClick, isInsuranceInfoFilled } from '../services/AdditionalStepService';

@Options({
	name: 'AdditionalInfoStepComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object, // Card from cms
		isValid: Boolean, // wether card is complete
		showInsuranceChoices: Boolean, // wether to show current and existing insurance choice
		existingInsuranceLabel: String,
		showValidNowOption: Boolean,
		showCommon: { type: Boolean, default: true }, // wether to show this ui - used for wait on slot questions
	},
})
export default class AdditionalInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete
	showInsuranceChoices: boolean; // wether to show current and existing insurance choice
	existingInsuranceLabel: string;
	showValidNowOption: boolean;
	showCommon?: boolean; // wether to show this ui - used for wait on slot questions

	public model!: any; // datastructure for user input (store)
	public cms!: DogSettings | TravelSettings | AccidentSettings | HomeSettings | HouseSettings; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;

	public jaNejArray: Array<ValuePair> = jaNejArray;
	public validNowArray: Array<ValuePair> = validNowArray;
	public skaderOptions = ['0', '1', '2', '3+'];
	public skaderLabels = this.skaderOptions;

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public selectExistingInsurance(evt) {
		this.model.additionalInfo.existingInsurance = evt.selectedValue;
		const inx = this.cms.insuranceCompanies.indexOf(evt.selectedValue);
		this.model.additionalInfo.existingInsuranceId = this.cms.insuranceCompaniesIds[inx];
	}

	public get isInsuranceInfoFilled() {
		return isInsuranceInfoFilled(this);
	}

	public handleRadioClick(evt: any) {
		const name = evt.name ? evt.name : evt.id;
		handleAdditionalStepClick(evt.value, name, this.model, this.cms);
		if (name === 'existInsurance') {
			this.model.additionalInfo.existInsurance = evt.value;
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}

	public get skaderOk() {
		return this.model.additionalInfo.skader && this.cms.damagesOk.includes(this.model.additionalInfo.skader);
	}

	public getNextCardLinkText(): string {
		return getNextCardLinkText(this);
	}
}
