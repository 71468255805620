import { CalculatorUiComponent } from '../BuyInsuranceComponent';
import {
	buildExistingProductsDescription,
	buildCreditCardInfo,
	buildPersonInfo,
	buildCurrentInsuranceInfo,
	buildPaymentInfo,
	handleOrder,
	dogFields,
} from '../services/OrderService';
import MapOrderFlow from '../types/MapOrderFlow';

export default class MapOrderDescription extends MapOrderFlow {
	public constructor(uiComp: CalculatorUiComponent) {
		super(uiComp);
	}

	public async orderByEmail() {
		await handleOrder(this.uiComp, 'Dog submitOrder');
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		desc += buildPersonInfo(this.model);

		desc += buildExistingProductsDescription(this.model, this.cms);

		desc += buildCurrentInsuranceInfo(this.model);

		desc += '<h2>Ønsket forsikring</h2>';

		desc += dogFields(this.model, this.pack);

		desc += buildPaymentInfo(this.model, this.pack, 'VP_VP beregning');

		desc += buildCreditCardInfo(this.model, this.cms);

		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
