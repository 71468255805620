import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import BuyInsuranceSettings from '../BuyInsuranceSettings';
import { plusCustomerDiscount, PlusCustomerType } from './ProductService';
import { getDiscount } from './CalculationService';
import store from '@/store/store';
import { formatPercent } from './ValidationService';

export interface Suggestion {
	discount: number;
	plusCustomer: PlusCustomerType;
	suggestProduct: string;
	suggestMultipleProducts?: boolean;
}

const getSecondaryProductsCount = (productList?: string[]): number => {
	let hasUlykke = false;
	let hasHouse = false;
	let hasCar = false;
	let secondaryProducts = 0;
	productList.forEach((productName) => {
		switch (productName) {
			case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
			case InsurancesProductEnum.ULYKKES_FORSIKRING:
			case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
				if (!hasUlykke) {
					hasUlykke = true;
					secondaryProducts++;
				}
				break;
			case InsurancesProductEnum.HUS_FORSIKRING:
			case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
				if (!hasHouse) {
					hasHouse = true;
					secondaryProducts++;
				}
				break;
			case InsurancesProductEnum.BIL_FORSIKRING:
				if (!hasCar) {
					hasCar = true;
					secondaryProducts++;
				}

				break;
		}
	});
	return secondaryProducts;
};

export const getSuggestedProduct = (
	cms: BuyInsuranceSettings,
	floorProperty: string,
	productList?: string[]
): Suggestion => {
	let hasIndbo = false;
	const primaryProducts = cms.discountPrimaryProducts.map((name) => name.toLowerCase());

	productList.forEach((productName) => {
		if (primaryProducts.includes(productName)) {
			hasIndbo = true;
		}
	});

	const secondaryProductsCount = getSecondaryProductsCount(productList);
	const suggestion: Suggestion = {
		discount: 0,
		plusCustomer: PlusCustomerType.PK0,
		suggestProduct: undefined,
	};
	if (!hasIndbo) {
		suggestion.suggestProduct = InsurancesProductEnum.INDBO_FORSIKRING;
		noIndbo(secondaryProductsCount, suggestion, cms);
	} else {
		// hasIndbo
		containsIndbo(secondaryProductsCount, suggestion, cms, floorProperty, productList);
	}
	if (!hasIndbo && secondaryProductsCount === 0) {
		// send to basket with 2 products selected
		suggestion.suggestProduct = cms.multiplePkStartBtnUpSale;
		suggestion.suggestMultipleProducts = true;
		setPlusCustomer(suggestion, PlusCustomerType.PK1);
	}

	return suggestion;
};

const setPlusCustomer = (suggestion: Suggestion, plusCustomerType: PlusCustomerType) => {
	suggestion.discount = plusCustomerDiscount.get(plusCustomerType);
	suggestion.plusCustomer = plusCustomerType;
};

const noIndbo = (secondaryProductsCount: number, suggestion: Suggestion, cms: BuyInsuranceSettings) => {
	if (secondaryProductsCount === 0) {
		// send to basket with 2 products selected
		suggestion.suggestProduct = cms.multiplePkStartBtnUpSale;
		suggestion.suggestMultipleProducts = true;
		setPlusCustomer(suggestion, PlusCustomerType.PK1);
		return;
	}

	switch (secondaryProductsCount) {
		case 1:
			setPlusCustomer(suggestion, PlusCustomerType.PK1);
			break;
		case 2:
			setPlusCustomer(suggestion, PlusCustomerType.PK2);
			break;
		case 3:
			setPlusCustomer(suggestion, PlusCustomerType.PK3);
			break;
		default:
			setPlusCustomer(suggestion, PlusCustomerType.PK0);
			break;
	}
};

const containsIndbo = (
	secondaryProductsCount: number,
	suggestion: Suggestion,
	cms: BuyInsuranceSettings,
	floorProperty: string,
	productList?: string[]
) => {
	let discountSecondarySuggestOrder = cms.secondarySuggestOrderUpSale;
	if (floorProperty) {
		discountSecondarySuggestOrder = cms.secondarySuggestOrderUpSale.filter(
			(name) => name !== InsurancesProductEnum.HUS_FORSIKRING
		);
	}

	switch (secondaryProductsCount) {
		case 0:
			setPlusCustomer(suggestion, PlusCustomerType.PK1);
			break;
		case 1:
			setPlusCustomer(suggestion, PlusCustomerType.PK2);
			break;
		default:
			setPlusCustomer(suggestion, PlusCustomerType.PK3);
			break;
	}
	discountSecondarySuggestOrder.forEach((product) => {
		if (!suggestion.suggestProduct && !productList.includes(product)) {
			suggestion.suggestProduct = product;
		}
	});

	if (
		productList.includes(InsurancesProductEnum.HUS_FORSIKRING) &&
		!productList.includes(InsurancesProductEnum.BIL_FORSIKRING)
	) {
		suggestion.suggestProduct = InsurancesProductEnum.BIL_FORSIKRING;
	}
	if (!floorProperty && !productList.includes(InsurancesProductEnum.BIL_FORSIKRING)) {
		suggestion.suggestProduct = InsurancesProductEnum.BIL_FORSIKRING;
	}
};

export const getSuggestedProductsFlow = (
	cms: any
): {
	suggestedProducts: string;
	discountDisplay: string;
} => {
	const rebate = getDiscount(cms);
	if (rebate.discount === 0.85) {
		// KLK start recommending other products with different text
		return undefined;
	}

	const basket = store.getters.getSelectableGroupUnique('basket');

	let products = [];
	let personInfo = store.getters.getCalculatorCommonModel?.personInfo;

	if (!personInfo?.almbrandCustomer) {
		// get from active calculator
		personInfo = store.getters.getActiveCalculator?.model?.personInfo;
	}

	if (personInfo?.existingAlmBrandProducts) {
		products = Object.assign([], personInfo.existingAlmBrandProducts);
		products = products.map((product) => product.toLocaleLowerCase());
	}

	basket.forEach((product) => {
		if (!products.includes(product)) {
			products.push(product);
		}
	});

	const suggestion = getSuggestedProduct(cms, personInfo?.floorNr, products);
	if (suggestion.suggestMultipleProducts) {
		return {
			suggestedProducts:
				'at tilføje <strong>' +
				cms.discountPrimaryProducts[0] +
				'</strong> og eksempelvis <strong>' +
				cms.discountSecondaryProducts[0] +
				'</strong>',
			discountDisplay: '5 %',
		};
	}
	const txt = suggestion.suggestProduct === InsurancesProductEnum.INDBO_FORSIKRING ? '' : 'eksempelvis ';
	return {
		suggestedProducts: txt + 'at tilføje ' + suggestion.suggestProduct + '</strong>',
		discountDisplay: formatPercent.format(suggestion.discount),
	};
};

export const setSuggestText = (uiComp, cms: BuyInsuranceSettings) => {
	if (cms.suggestTextMiniBasket) {
		uiComp.suggestText = uiComp.getSuggestText();
		uiComp.suggestText1 = uiComp.getSuggestText1();
	}
};
export const getSuggestText = (uiComp, cms): string => {
	if (!cms.suggestProductsText) {
		return undefined;
	}
	uiComp.suggest = getSuggestedProductsFlow(cms);
	if (!uiComp.suggest) {
		return undefined;
	}
	return cms.suggestProductsText
		.replace('¤discount', uiComp.suggest.discountDisplay)
		.replace('¤products', uiComp.suggest.suggestedProducts);
};
export const getSuggestText1 = (uiComp, cms: BuyInsuranceSettings): string => {
	if (uiComp.suggest && cms.suggestProductsText1) {
		return cms.suggestProductsText1
			.replace('¤discount', uiComp.suggest.discountDisplay)
			.replace('¤products', uiComp.suggest.suggestedProducts);
	}
	return undefined;
};
