import { mapState } from 'vuex';
import { CardBlock } from '@/cms/definitions/content-types';
import { BasketSettings } from '../BasketSettings';
import FieldValidator from '@/views/calculators/services/fieldValidatorService';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { formatNumber, monthYearArray, Validator, ValuePair } from '../../services/ValidationService';
import { trackPurchase, trackReceipt } from '@/views/calculators/services/trackingUtility';

@Options({
	name: 'PaymentBasketStepComponent',
	components: {},
	computed: mapState<any>({
		basketModel: (state) => state.calculatorContext.basket.model,
		basketCms: (state) => state.calculatorContext.basket.cms,
	}),
	props: {
		card: Object, // Card from cms
		isValid: Boolean, // wether card is complete
	},
})
export default class PaymentBasketStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete

	public basketModel!: any; // datastructure for user input (store)
	public basketCms!: BasketSettings; // settings from CMS (store)
	public monthYearArray: Array<ValuePair> = monthYearArray;

	public monthlyPrice = 0;
	public yearlyPrice = 0;
	public monthlyDisplayPrice: string = null;
	public yearlyDisplayPrice: string = null;
	public missingCalculations: string = null;
	public fieldValidator: FieldValidator = Validator;

	public productNames: Array<string> = [];
	public oneSubmit = false;

	public async created() {
		this.missingCalculations = '';
		this.productNames = await store.getters.getSelectableGroup('basket');
		this.monthlyPrice = this.getMonthlyTotalPrice(true);
		this.yearlyPrice = this.getMonthlyTotalPrice(false);
		if (this.monthlyPrice === 0) {
			this.monthlyDisplayPrice = 'Månedlig betaling';
			this.yearlyDisplayPrice = 'Årlig betaling';
		} else {
			this.monthlyDisplayPrice = formatNumber.format(this.monthlyPrice) + ' kr./md';
			this.yearlyDisplayPrice = formatNumber.format(this.yearlyPrice) + ' kr./år';
		}
	}

	public mounted(): void {
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public chooseMonthYear(evt) {
		this.basketModel.choosePackage.monthYear = evt.value;
		this.basketModel.payment.userSelected = true;
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public getYearlySavingsTxt() {
		const amount = this.monthlyPrice * 12 - this.yearlyPrice;
		const res = formatNumber.format(amount) + ' kr.';
		return this.basketCms.yearlySavings ? this.basketCms.yearlySavings.replace('¤yearlySaving', res) : '';
	}

	private getMonthlyTotalPrice(monthly: boolean): number {
		let totalPrice = 0;
		this.productNames.forEach((productName) => {
			const calculator = store.getters.getCalculator(productName);
			if (calculator?.model?.readyForBasket) {
				if (monthly) {
					totalPrice += calculator.model.choosePackage.selectedPackage.totalPrice;
				} else {
					totalPrice += calculator.model.choosePackage.selectedPackage.yearlyPriceTotal;
				}
			} else {
				this.missingCalculations = 'Nogle forsikringer i din kurv indgår ikke i prisen';
			}
		});
		return totalPrice;
	}

	public async nextStep() {
		if (!this.isValid) {
			this.basketModel.pristineStep.set(this.card.name, false);
		}

		if (!this.oneSubmit && this.isValid) {
			this.oneSubmit = true;
			await trackPurchase();
			await trackReceipt();
			this.$emit('nextStep', this.card.routePath);
		}
	}
}
