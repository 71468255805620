import { mapState } from 'vuex';
import FieldValidator from '@/views/calculators/services/fieldValidatorService';

import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { FamilySettings } from '../family/FamilySettings';
import { AccidentChildrenSettings } from '../accidentChildren/AccidentChildrenSettings';
import { Validator, ValuePair, jaNejArray, validNowArray } from '../services/ValidationService';
import { handleAdditionalStepClick, isValidFromFilled } from '../services/AdditionalStepService';
import { getNextCardLinkText } from '../services/NavigationService';

@Options({
	name: 'AdditionalInfoFamilyChildStepComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
		existingInsuranceLabel: String,
		showValidNowOption: Boolean,
	},
})
export default class AdditionalInfoFamilyChildStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete
	existingInsuranceLabel: string;
	showValidNowOption: boolean;

	public model!: any; // datastructure for user input (store)
	public cms!: FamilySettings | AccidentChildrenSettings; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;

	public jaNejArray: Array<ValuePair> = jaNejArray;
	public validNowArray: Array<ValuePair> = validNowArray;
	public skaderOptions = ['0', '1', '2', '3+'];
	public skaderLabels = this.skaderOptions;
	public isChildAccident = false;

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
		this.isChildAccident = this.model.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING;
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public selectExistingInsuranceFamily(evt) {
		this.model.additionalInfo.existingInsurance = evt.selectedValue;
		const index = this.cms.insuranceCompanies.indexOf(evt.selectedValue);
		this.model.additionalInfo.existingInsuranceId = this.cms.insuranceCompaniesIds[index];
	}

	public selectExistingInsuranceChild(evt) {
		const id = evt.id.replace(/\D/g, ''); // find child ID
		this.model.familyInfo[evt.id] = evt.selectedValue;
		const index = this.cms.insuranceCompanies.indexOf(evt.selectedValue);
		this.model.familyInfo['existingInsuranceId' + id] = this.cms.insuranceCompaniesIds[index];
		this.model.familyInfo[evt.id] = evt.selectedValue;
	}

	public get showPersonsBlock() {
		if (
			this.isChildAccident ||
			this.model.additionalInfo.existInsurance === 'nej' ||
			(this.model.additionalInfo.existInsurance === 'ja' && this.model.additionalInfo.existingInsurance)
		) {
			return true;
		}
		return false;
	}

	public get isInsuranceInfoFilled() {
		if (!this.showValidNowOption) {
			return false;
		}
		if (!this.showPerson(this.model.familyInfo.personCountId + 1)) {
			return false;
		}

		return isValidFromFilled(this.model);
	}

	public showPerson(inx: number): boolean {
		if (!this.showValidNowOption) {
			return false;
		}
		if (inx === 0) {
			return true;
		}
		const inxPrev = inx - 1;
		if (this.model.familyInfo['existInsurance' + inxPrev] === undefined) {
			return false;
		}
		if (this.model.familyInfo['existInsurance' + inxPrev] === 'ja') {
			if (this.model.familyInfo['existingInsurance' + inxPrev] === undefined) {
				return false;
			}
		}
		return true;
	}

	public handleRadioClick(evt: any) {
		const propName = evt.name ? evt.name : evt.id;
		const cleanedName = propName.replace(/\d/g, '');
		handleAdditionalStepClick(evt.value, cleanedName, this.model, this.cms);
		if (cleanedName === 'existInsurance') {
			if (propName === cleanedName) {
				this.model.additionalInfo.existInsurance = evt.value;
			} else {
				this.model.familyInfo[propName] = evt.value;
			}
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}

	public get skaderOk() {
		return this.model.additionalInfo.skader && this.cms.damagesOk.includes(this.model.additionalInfo.skader);
	}

	public getNextCardLinkText(): string {
		return getNextCardLinkText(this);
	}
	public getName(inx: number): string {
		return this.model.familyInfo['name' + inx];
	}

	public getExistingInsuranceLabel(inx: number): string {
		return (
			'Er der en eksisterende Ulykkesforsikring for <b>' +
			this.model.familyInfo['name' + inx] +
			'</b>, som vi skal opsige?'
		);
	}
}
