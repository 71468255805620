import { CalculatorUiComponent } from '../BuyInsuranceComponent';
import {
	addProp,
	buildExistingProductsDescription,
	buildCreditCardInfo,
	buildPersonInfo,
	buildCurrentInsuranceInfo,
	buildPaymentInfo,
	handleOrder,
} from '../services/OrderService';
import MapOrderFlow from '../types/MapOrderFlow';

export default class MapOrderDescription extends MapOrderFlow {
	public constructor(uiComp: CalculatorUiComponent) {
		super(uiComp);
	}

	public async orderByEmail() {
		const specificFields = {
			desc: '',
			tracking: '',
		};
		if (this.model.personInfo.customerAge > this.cms.seniorStep1) {
			specificFields.desc += `<li><h2><i>Senior kunde (${this.model.personInfo.customerAge} år), tjek dækninger</i></h2></li>`;
			specificFields.tracking += '/seniorcustomer';
		}
		await handleOrder(this.uiComp, 'Accident submitOrder', specificFields);
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		desc += buildPersonInfo(this.model);

		desc += buildExistingProductsDescription(this.model, this.cms);

		desc += buildCurrentInsuranceInfo(this.model);

		desc += '<h2>Ønsket forsikring</h2>';
		desc += addProp('Forsikringstype', this.pack.name);
		if (!this.model.personInfo.isMainPerson && this.model.personInfo.otherName) {
			desc += addProp('Navn', this.model.personInfo.otherName);
			desc += addProp('Alder', this.model.personInfo.otherCustomerAge);
			desc += addProp('Cpr', this.model.personInfo.otherCpr);
		}

		desc += addProp('Arbejde', `${this.model.personInfo.work}`);
		desc += addProp('Arbejde-id', `${this.model.personInfo.workId}`);
		desc += '<br>';

		desc += buildPaymentInfo(this.model, this.pack, 'Tia beregning');

		desc += buildCreditCardInfo(this.model, this.cms);

		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
