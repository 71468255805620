import { mapState } from 'vuex';
import { CardBlock } from '@/cms/definitions/content-types';
import { CarSettings } from '../car/CarSettings';
import { AccidentSettings } from '../accident/AccidentSettings';
import { HomeSettings } from '../home/HomeSettings';
import { HouseSettings } from '../house/HouseSettings';
import { DISCOUNT_UPDATE } from '@/store/modules/calculatorContext';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { DogSettings } from '../dog/DogSettings';
import store from '@/store/store';
import { getDiscount, handleIncludeDiscountPlusCustomer } from '../services/CalculationService';
import { jaNejArray, ValuePair } from '../services/ValidationService';
import { TravelSettings } from '../travel/TravelSettings';
import { FamilySettings } from '../family/FamilySettings';

@Options({
	name: 'ExistingAbProductsComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object as PropType<CardBlock>,
	},
})
export default class ExistingAbProductsComponent extends Vue {
	card: CardBlock; // Card from cms

	public model!: any; // datastructure for user input (store)
	cms!: DogSettings | CarSettings | AccidentSettings | HomeSettings | HouseSettings | TravelSettings | FamilySettings; // settings from CMS (store)
	public plusCustomerInfo = '';

	public jaNejArray: Array<ValuePair> = jaNejArray;
	public discountProductsFiltered: Array<string> = [];
	public updateKeys: Map<string, boolean> = new Map();
	public campaignHasDiscount = false;

	public async created() {
		const basket = store.getters.getSelectableGroupUnique('basket');
		this.discountProductsFiltered = this.cms.getDiscountProducts(basket);

		this.model.personInfo.existingAlmBrandProducts.forEach((product) => {
			this.updateKeys.set(product, true);
		});
	}
	public async mounted() {
		if (this.model.campaign?.valid && this.model.campaign?.discount > 0) {
			this.campaignHasDiscount = true;
		}
		// set discount on reentry
		this.setDiscount();
	}

	public radioEvent(evt) {
		this.model.personInfo.almbrandCustomer = evt.value;
		if (this.model.personInfo.almbrandCustomer === 'nej') {
			this.model.personInfo.existingAlmBrandProducts = [];
			this.updateKeys.forEach((val, key) => {
				this.updateKeys.set(key, false);
			});
			this.updateKeys.clear();
		} else if (this.discountProductsFiltered.length === 1) {
			// this.model.personInfo.almbrandCustomer === 'ja' and all pluscustomer products in the basket
			this.model.personInfo.existingAlmBrandProducts = [this.cms.discountProductNone];
		}
		this.model.pristineStep.set(this.card.name, true);

		this.setDiscount();
	}

	public checkboxEvent(selectedProduct) {
		if (selectedProduct.checked) {
			this.model.personInfo.existingAlmBrandProducts.push(selectedProduct.name);
			if (selectedProduct.name === this.cms.discountProductNone) {
				// remove any selected products
				this.model.personInfo.existingAlmBrandProducts = this.model.personInfo.existingAlmBrandProducts.filter(
					(product) => product === this.cms.discountProductNone
				);
				this.updateKeys.clear();
				this.updateKeys.set(this.cms.discountProductNone, true);
			} else {
				// remove "none selected products" if selected
				this.model.personInfo.existingAlmBrandProducts = this.model.personInfo.existingAlmBrandProducts.filter(
					(product) => product !== this.cms.discountProductNone
				);
				this.updateKeys.set(selectedProduct.name, true);
				this.updateKeys.set(this.cms.discountProductNone, false);
			}
		} else {
			this.model.personInfo.existingAlmBrandProducts = this.model.personInfo.existingAlmBrandProducts.filter(
				(product) => product !== selectedProduct.name
			);
		}
		this.setDiscount();
		store.dispatch(DISCOUNT_UPDATE);
	}

	private setDiscount(): void {
		this.model.calculation.discount = 1;
		this.model.calculation.discountDisplay = undefined;
		this.plusCustomerInfo = this.campaignHasDiscount
			? this.cms.plusCustomerNoDiscount
			: this.cms.plusCustomerNoDiscountNoCampaignDiscount;
		const rebate = getDiscount(this.cms, true);

		switch (rebate.discount) {
			case 0.95:
				this.model.calculation.discount = 0.95;
				this.plusCustomerInfo = this.cms.plusCustomerDiscount.replace('¤discount', '5%');
				break;
			case 0.9:
				this.model.calculation.discount = 0.9;
				this.plusCustomerInfo = this.cms.plusCustomerDiscount.replace('¤discount', '10%');
				break;

			case 0.85:
				this.model.calculation.discount = 0.85;
				this.plusCustomerInfo = this.cms.plusCustomerDiscount.replace('¤discount', '15%');
				break;
			default:
				this.model.calculation.discount = 1;
		}

		this.model.calculation.discountDisplay = rebate.discountDisplay;
		handleIncludeDiscountPlusCustomer(this.model);
	}

	public get customerErr() {
		return !this.model.pristineStep.get(this.card.name) && !this.model.personInfo.almbrandCustomer;
	}

	public get errorProducts() {
		// KLK, er dette ok: model.personInfo.existingAlmBrandProducts.length < 1
		return (
			!this.model.pristineStep.get(this.card.name) &&
			this.model.personInfo.almbrandCustomer === 'ja' &&
			this.model.personInfo.existingAlmBrandProducts.length < 1
		);
	}
}
