import { exception } from '@/appinsights/logging/ApplicationInsightLog';
import { CalculatorUiComponent } from '../BuyInsuranceComponent';
import {
	addProp,
	buildExistingProductsDescription,
	buildCreditCardInfo,
	buildPersonInfo,
	buildCurrentInsuranceInfo,
	buildPaymentInfo,
	handleOrder,
} from '../services/OrderService';
import { formatNumber } from '../services/ValidationService';
import MapOrderFlow from '../types/MapOrderFlow';

export default class MapOrderDescription extends MapOrderFlow {
	public constructor(uiComp: CalculatorUiComponent) {
		super(uiComp);
	}

	public async orderByEmail() {
		await handleOrder(this.uiComp, 'Travel submitOrder');
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		desc += buildPersonInfo(this.model);

		desc += addProp('Antal personer i husstand', this.model.personInfo.familyMemberCount);
		// Kun Mastercard kampagne
		if (this.model.choosePackage.forfald) {
			desc += addProp('Forfald', this.model.choosePackage.forfald);
		}

		desc += buildExistingProductsDescription(this.model, this.cms);

		desc += buildCurrentInsuranceInfo(this.model);

		desc += '<h2>Ønsket forsikring</h2>';
		desc += addProp('Forsikringstype', this.pack.name);
		desc += addProp('Destination', this.model.choosePackage.segmentedProductProperty);

		try {
			if (this.model.choosePackage?.selectedPackage?.expandedCoverages?.includes('Afbestilling')) {
				const sumFormatted = formatNumber.format(this.cms.cancelSum);
				this.model.overview.details.push('Afbestillingssum: ' + sumFormatted + ' kr.');
				desc += addProp('Afbestillingssum', sumFormatted + ' kr.');
			}
		} catch (e) {
			exception(e);
			// Too bad
		}

		desc += buildPaymentInfo(this.model, this.pack, 'FP_FP beregning');

		desc += buildCreditCardInfo(this.model, this.cms);

		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
