import { isDevelop } from '@/views/calculators/services/environmentService';
import { STEP } from '../services/UiStepService';
import {
	CommonModel,
	Model,
	PersonInfoHomeFamily,
	mockAdditionalInfo,
	mockContactInformation,
	mockPersonInfo,
} from '../types/CommonModel';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
// list and order of steps
export const STEPS: Array<string> = [
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

class HolidayAddress {
	address: string;
	zipCode: string;
	zipName: string;
	streetName: string;
	houseNr: string;
	floorNr: string;
	doorNr: string;
	kvhxValue: string;
	copySafe = '';
}

export class PersonInfoHolidayHouse extends PersonInfoHomeFamily {
	holidayAddress = new HolidayAddress();
}
export class HouseModel extends CommonModel implements Model {
	public constructor() {
		//set at runtime: this.productName
		super(STEPS, undefined);
	}

	public personInfo = new PersonInfoHolidayHouse();

	public static mock(model: HouseModel) {
		if (isDevelop) {
			mockPersonInfo(model);

			mockAdditionalInfo(model);

			mockContactInformation(model);
			if (model.productName == InsurancesProductEnum.FRITIDSHUS_FORSIKRING) {
				Object.assign(model.personInfo.holidayAddress, {
					address: 'Bakkelyvej 62, Overby Lyng, 4583 Sjællands Odde',
					houseNr: '62',
					streetName: 'Bakkelyvej',
					zipCode: '4583',
					zipName: 'Sjællands Odde',
				});
			}
		}
	}
}
