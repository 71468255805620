import { createApp } from 'vue';
import App from '@/App.vue';
import EpiEdit from '@/cms/directives/epiEdit';
import router from './router';
import store from '@/store/store';
import appInsights, { AppInsightsOptions } from '@/appinsights/appinsights';
import '@/epiBootstrap';
import { defaultCmsConfig } from '@/cms/CmsConfig';
defaultCmsConfig.contentArea.rootHtmlTag = 'section';

import PopoverComponent from '@/sharedcomponents/baseComponents/popoverComponent/popoverComponent.vue';
import SegmentedControlComponent from '@/sharedcomponents/baseComponents/segmentedControlComponent/segmentedControlComponent.vue';
import SelectModalComponent from '@/sharedcomponents/baseComponents/selectModalComponent/selectModalComponent.vue';
import CheckboxComponent from '@/sharedcomponents/baseComponents/CheckboxComponent/CheckboxComponent.vue';
import RadioComponent from '@/sharedcomponents/baseComponents/RadioComponent/RadioComponent.vue';
import InputFieldComponent from '@/sharedcomponents/baseComponents/inputFieldComponent/InputFieldComponent.vue';
import DatePickerComponent from '@/sharedcomponents/baseComponents/datePickerComponent/DatePickerComponent.vue';
import SearchComponent from '@/sharedcomponents/baseComponents/searchComponent/SearchComponent.vue';
import MessageComponent from '@/sharedcomponents/baseComponents/messageComponent/messageComponent.vue';
import ToggleModalComponent from '@/sharedcomponents/baseComponents/toggleModalComponent/ToggleModalComponent.vue';
import AutocompleteComponent from '@/sharedcomponents/baseComponents/autoCompleteComponent/AutocompleteComponent.vue';
import AddressSearchComponent from '@/sharedcomponents/baseComponents/addressSearchComponent/AddressSearchComponent.vue';
import ButtonComponent from '@/sharedcomponents/baseComponents/buttonComponent/buttonComponent.vue';
import ButtonTapComponent from '@/sharedcomponents/baseComponents/buttonTapComponent/buttonTapComponent.vue';
import LinkComponent from '@/sharedcomponents/baseComponents/linkComponent/linkComponent.vue';
// Used in BasketPage
import TileBlock from '@/sharedcomponents/pageComponents/tileBlock/TileBlock.vue';
import { exception } from './appinsights/logging/ApplicationInsightLog';

// Register all Episerver view components globally. This requires webpack!
// Otherwise we need to register all components manually here in main.ts.
const app = createApp(App)
	.directive('epi-edit', EpiEdit)
	.use(store)
	.use(router)
	.use(appInsights, { router } as AppInsightsOptions)
	.component('PopoverComponent', PopoverComponent)
	.component('SegmentedControlComponent', SegmentedControlComponent)
	.component('CheckboxComponent', CheckboxComponent)
	.component('RadioComponent', RadioComponent)
	.component('InputFieldComponent', InputFieldComponent)
	.component('SelectModalComponent', SelectModalComponent)
	.component('DatePickerComponent', DatePickerComponent)
	.component('SearchComponent', SearchComponent)
	.component('MessageComponent', MessageComponent)
	.component('ToggleModalComponent', ToggleModalComponent)
	.component('AutocompleteComponent', AutocompleteComponent)
	.component('AddressSearchComponent', AddressSearchComponent)
	.component('ButtonTapComponent', ButtonTapComponent)
	.component('ButtonComponent', ButtonComponent)
	.component('TileBlock', TileBlock)
	.component('LinkComponent', LinkComponent);

const addPageComponents = (requireComponent) => {
	requireComponent.keys().forEach((fileName) => {
		const componentConfig = requireComponent(fileName);

		// Gets the component name regardless folder depth
		const namePart = fileName.split('/').pop() ?? '';
		const componentName = namePart.replace(/\.\w+$/, '');

		// Look for the component options on `.default`, which will
		// exist if the component was exported with `export default`,
		// otherwise fall back to module's root.
		// console.log("### adding CMS page componentName:", componentName);
		app.component(componentName, componentConfig.default || componentConfig);
	});
};

const requireComponent = require.context('@/views/calculators/pages', true, /.vue$/);
addPageComponents(requireComponent);

// remove any hashtag in url TODO KLK make recursive if multiple hashtags
try {
	const url = location.href;
	const anchorInx = url.indexOf('#');
	if (anchorInx > -1) {
		let doneReplace = false;
		const queryInx = url.indexOf('?');
		if (queryInx > -1) {
			if (queryInx > anchorInx) {
				const cleanUrl = url.replaceAll(url.substring(anchorInx, queryInx), '');
				location.href = cleanUrl;
				doneReplace = true;
			}
		}
		if (!doneReplace) {
			const cleanUrl = url.split('#')[0];
			location.href = cleanUrl;
		}
	}
} catch (e) {
	exception(e);
}

app.mount('#app');

declare global {
	interface Window {
		epi: {
			inEditMode: boolean;
			isEditable: boolean;
			subscribe(eventName: string, callback: (message: { url: string }) => void): void;
			ready: boolean;
		};
	}
}
