export const mapDawaAddress = (personInfo: any, dawa: any) => {
	personInfo.address = dawa.matchText;
	personInfo.zipCode = dawa.data.postnr;
	personInfo.zipName = dawa.data.postnrnavn;
	personInfo.streetName = dawa.data.vejnavn;
	personInfo.houseNr = dawa.data.husnr;
	personInfo.floorNr = dawa.data.etage;
	personInfo.doorNr = dawa.data.dør;
	personInfo.kvhxValue = dawa.data.kvhx;
};
export const resetAddress = (personInfo: any) => {
	personInfo.address = undefined;
	personInfo.zipCode = undefined;
	personInfo.zipName = undefined;
	personInfo.streetName = undefined;
	personInfo.houseNr = undefined;
	personInfo.floorNr = undefined;
	personInfo.doorNr = undefined;
	personInfo.kvhxValue = undefined;
};
