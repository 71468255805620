import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/views/calculators/services/environmentService';
import {
	CommonModel,
	Model,
	PersonInfoCommon,
	mockAdditionalInfo,
	mockContactInformation,
	mockPersonInfo,
} from '../types/CommonModel';
// list and order of steps

export const STEPS: Array<string> = [
	STEP.DOG_INFO,
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class DogModel extends CommonModel implements Model {
	public dogInfo = {
		subtitle: undefined,
		dogCount: undefined, // dogCount value
		dogName1: undefined,
		dogName2: undefined,
		dogType1: undefined, // string
		dogType2: undefined,
		dogTypeId1: undefined, // string
		dogTypeId2: undefined,
		gender1: undefined, // T, H, U -> TÆVE, HAN, Uoplyst
		gender2: undefined,
		birthdate1: undefined, // dd-MM-yyyy
		birthdate2: undefined,
		// answers for both dogs if 2 dogs selected
		healthy: undefined, // Y
		beenUnhealthy: undefined, // N
		vet: undefined, // N
		vaccine: undefined, // Y
	};

	public constructor() {
		super(STEPS, InsurancesProductEnum.HUNDE_FORSIKRING);
	}

	public personInfo = new PersonInfoCommon();

	public static mock(model: DogModel) {
		if (isDevelop) {
			mockPersonInfo(model);

			Object.assign(model.dogInfo, {
				subtitle: '',
				dogCount: 1,
				dogName2: 'King',
				dogName1: 'Fido',
				dogType2: 'Engelsk bulldog',
				dogType1: 'Barbet',
				dogTypeId2: '101',
				dogTypeId1: '25',
				gender2: 'T',
				gender1: 'H',
				birthdate1: '12-10-2021',
				birthdate2: '12-10-2021',
				// answers for both dogs if 2 dogs selected
				healthy: 'ja',
				beenUnhealthy: 'nej',
				vet: 'nej',
				vaccine: 'ja',
			});

			mockAdditionalInfo(model);

			mockContactInformation(model);
		}
	}
}
