import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: "upsale",
  class: "highlights fill mb-4 text-left"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = {
  class: "mb-5 p-3 innerBox",
  key: "overview-details"
}
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "grow" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "mt-3 flex"
}
const _hoisted_10 = { class: "grow" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "button-container text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_buttonComponent = _resolveComponent("buttonComponent")!

  return (_ctx.init && _ctx.upSaleProducts?.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "pt-2",
          innerHTML: _ctx.suggestText()
        }, null, 8, _hoisted_2),
        (_ctx.showExistingProducts)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['show-hide-details mb-3', { open: _ctx.showOrderDetails }]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOrderDetails && _ctx.toggleOrderDetails(...args))),
              textContent: _toDisplayString(_ctx.showOrderDetails ? _ctx.cms.toggleTitleOpenUpSale : _ctx.cms.toggleTitleClosedUpSale)
            }, null, 10, _hoisted_3))
          : _createCommentVNode("", true),
        _createVNode(_Transition, { name: "slide" }, {
          default: _withCtx(() => [
            (_ctx.showOrderDetails && _ctx.showExistingProducts)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upSaleProducts, (product) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: product.productName
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", {
                            innerHTML: _ctx.getProductHighlights(product)
                          }, null, 8, _hoisted_7)
                        ]),
                        _createElementVNode("div", {
                          innerHTML: _ctx.getProductHighlightsAmount(product)
                        }, null, 8, _hoisted_8)
                      ])
                    ]))
                  }), 128)),
                  (_ctx.getProductHighlightsSummed())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("span", {
                            innerHTML: _ctx.cms.productTotalAmountTxtUpSale
                          }, null, 8, _hoisted_11)
                        ]),
                        _createElementVNode("div", {
                          innerHTML: _ctx.getProductHighlightsSummed()
                        }, null, 8, _hoisted_12)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_buttonComponent, {
            btnClass: _ctx.cms.btnClassUpSale,
            label: _ctx.btnLabel,
            onClick: _ctx.navigateToProduct
          }, null, 8, ["btnClass", "label", "onClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}