import { mapState } from 'vuex';
import { Options, Vue } from 'vue-class-component';
import { DogSettings } from '../dog/DogSettings';
import { BasketSettings } from '../basket/BasketSettings';
import { isOpenHours } from '../services/AvailabilityService';
import { modalWithExit, togglePopup } from '../services/AlertService';
import { trackContact } from '@/views/calculators/services/trackingUtility';

@Options({
	name: 'ContactComponent',
	components: {},
	computed: mapState<any>({
		productModel: (state) => state.calculatorContext[state.calculatorContext.active].model,
		productCms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
		basketModel: (state) => state.calculatorContext.basket.model,
		basketCms: (state) => state.calculatorContext.basket.cms,
	}),
	props: {
		name: String,
		useBasketModel: { type: Boolean, default: false },
	},
})
export default class ContactComponent extends Vue {
	name: string; // name for tracking
	useBasketModel?: boolean;

	public productModel!: any; // datastructure for user input (store)
	public basketModel!: any; // datastructure for basket
	public productCms!: any; // datastructure for user input (store)
	public basketCms!: any; // datastructure for basket

	public cms: DogSettings /*| TravelSettings*/ | BasketSettings; // settings from CMS (store; // settings from CMS (store)
	public model: any = null;

	public showCall = true;
	public showWrite = true;
	public showOffer = true;

	public created() {
		this.model = this.useBasketModel ? this.basketModel : this.productModel;
		this.cms = this.useBasketModel ? this.basketCms : this.productCms;
	}
	public mounted() {
		this.handleShowCall();
		this.showWrite = this.cms.contactCanWrite;
		this.showOffer = this.cms.contactCompleteOffer;
	}

	private handleShowCall() {
		if (this.cms.contactCanCall) {
			this.showCall = isOpenHours(this.cms.openForCall, this.cms.closingDaysForCall);
		} else {
			this.showCall = false;
		}
	}

	public openModal(phone: boolean) {
		const modal = modalWithExit(this.cms);
		modal.content = this.showCall && phone ? this.cms.contactText : this.cms.contactTextNoCall;
		modal.title = this.cms.contactTitle;
		modal.id = 'contact_popup';
		togglePopup(this.cms, modal);
		trackContact();
	}
}
