import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/views/calculators/services/environmentService';
import {
	CommonModel,
	Model,
	PersonInfoAccident,
	mockAdditionalInfo,
	mockContactInformation,
	mockPersonInfo,
} from '../types/CommonModel';

// list and order of steps
export const STEPS: Array<string> = [
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class AccidentModel extends CommonModel implements Model {
	public constructor() {
		super(STEPS, InsurancesProductEnum.ULYKKES_FORSIKRING);
	}

	public personInfo = new PersonInfoAccident();

	public static mock(model: AccidentModel) {
		if (isDevelop) {
			mockPersonInfo(model);

			Object.assign(model.personInfo, {
				work: 'Asfaltfabrik',
				workId: '0024',
				// floorNr: 4,
			});

			mockAdditionalInfo(model);

			mockContactInformation(model);
		}
	}
}
