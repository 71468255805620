import { HtmlBlock } from '@/cms/definitions/content-types';
import BuyInsuranceSettings from '../BuyInsuranceSettings';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

export class HouseSettings extends BuyInsuranceSettings {
	public changeCalcTitle: string;
	public changeCalcContent: string;
	public calculatorUrlOther: string;
	public calculatorUrl: string;
	public selectedRisksOther: Array<string>;
	public basePackageOther: number;
	public excessIdOther: number;

	public insuredValue: number; // holidayHouse default sum, being copied from product info

	public constructor(settingsBlock: HtmlBlock) {
		super(settingsBlock);
		this.loadHouseSettings();
	}

	/**
	 * load settings from CMS if present
	 */
	private loadHouseSettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');
			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				if (setting.indexOf('changecalctitle=') > -1) {
					this.changeCalcTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('changecalccontent=') > -1) {
					this.changeCalcContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('calculatorurlother=') > -1) {
					this.calculatorUrlOther = this.getSettingsValue(setting_);
				} else if (setting.indexOf('calculatorurl=') > -1) {
					this.calculatorUrl = this.getSettingsValue(setting_);
				} else if (setting.indexOf('selectedrisksother=') > -1) {
					this.selectedRisksOther = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('basepackageother=') > -1) {
					this.basePackageOther = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('excessidother=') > -1) {
					this.excessIdOther = parseInt(this.getSettingsValue(setting_));
				}
			});
		} catch (e) {
			exception(e);
		}
	}
}
