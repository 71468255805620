import { CalculatorUiComponent } from '../BuyInsuranceComponent';
import {
	addProp,
	buildExistingProductsDescription,
	buildCreditCardInfo,
	buildPersonInfo,
	buildCurrentInsuranceInfo,
	buildPaymentInfo,
	homeFamilyHouseSpecificFields,
	handleOrder,
} from '../services/OrderService';
import MapOrderFlow from '../types/MapOrderFlow';

export default class MapOrderDescription extends MapOrderFlow {
	public constructor(uiComp: CalculatorUiComponent) {
		super(uiComp);
	}

	public async orderByEmail() {
		const specificFields = homeFamilyHouseSpecificFields(this.uiComp);
		await handleOrder(this.uiComp, 'Home submitOrder', specificFields);
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		desc += buildPersonInfo(this.model);

		desc += addProp('Stråtag', `${this.model.personInfo.thatchedRoof}`);
		desc += addProp('Antal personer i husstand', this.model.personInfo.familyMemberCount);

		desc += buildExistingProductsDescription(this.model, this.cms);

		desc += buildCurrentInsuranceInfo(this.model);

		desc += '<h2>Ønsket forsikring</h2>';
		desc += addProp('Forsikringstype', this.pack.name);
		desc += addProp('Selvrisiko', (this.uiComp as any).getExcessAmount());
		desc += addProp('Forsikret indbo sum', this.model.choosePackage.insuredValue);

		desc += buildPaymentInfo(this.model, this.pack, 'FP_FP beregning');

		desc += buildCreditCardInfo(this.model, this.cms);

		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
