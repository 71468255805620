import { information } from '@/appinsights/logging/ApplicationInsightLog';
import { defaultConfig } from '@/cms/api/ApiConfig';
import { handleIdleLogout, isDevelop } from '@/views/calculators/services/environmentService';
import { ActionContext } from 'vuex';

export const UPDATE_AUTH = 'authentication/UPDATE_AUTH';

const UPDATE_AUTHENTICATION = 'authentication/UPDATE_AUTHENTICATION';

const _logProperties = {
	SourceContext: 'Authentication',
	context: 'calculators',
};
export interface AuthenticationState {
	isLoaded: boolean;
	authenticated: boolean;
	expire: number;
}

const state: AuthenticationState = {
	isLoaded: false,
	authenticated: false,
	expire: 0,
};

const mutations = {
	[UPDATE_AUTHENTICATION](state: AuthenticationState, resp: any) {
		const expire: Date = new Date(resp.expire);
		const expireNumber = expire.getTime();
		state.isLoaded = true;
		state.authenticated = resp.authenticated;
		state.expire = expireNumber;
		if (state.authenticated) {
			handleIdleLogout();
		}
	},
};

const actions = {
	async [UPDATE_AUTH]({ commit, state, dispatch }: ActionContext<AuthenticationState, any>) {
		if (!state.isLoaded) {
			//const url = window.location.protocol + "//" + window.location.host + "/api/v1/auth/tokenexpire";
			const url = defaultConfig.baseUrl + '/api/v1/auth/tokenexpire';
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (response.status == 200) {
				if (isDevelop) {
					if (process.env?.VUE_APP_CALCULATOR_TESTUSER === 'true' || location.href.indexOf('customer') > 0) {
						information('DEV customer loaded', _logProperties);
						commit(UPDATE_AUTHENTICATION, {
							authenticated: true,
							expire: '2034-02-24T20:42:59Z',
						});
					} else {
						const data = await response.json();
						commit(UPDATE_AUTHENTICATION, data);
					}
				} else {
					const data = await response.json();
					commit(UPDATE_AUTHENTICATION, data);
				}
			}
		}
	},
};

export default {
	state,
	mutations,
	actions,
};
